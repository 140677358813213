#container-lol{
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
}

#popout{
    width: 100%;
    position: fixed;
    z-index: 500000;
    margin: auto;
    color: white;
    display: grid;
    place-items: center;
    height: 106vh;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

#background{
    width: 100%;
    background-color: #00000085;
    position: fixed;
    height: 200vh;
    z-index: 500000;
    margin-top: -20px;
}

#container{
    background-color: rgb(58, 58, 58);
    padding: 20px;
    border-radius: 0.5rem;
    z-index: 1000000;
    margin: 2.5rem;
    max-width: 400px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

h3{
    margin: 0;
    padding: 0;
}

button{
    background-color: rgb(0, 187, 0);
    border: 0px;
    border-radius: 0.5rem;
    color: white;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

#deny{
    background-color: red;
}