#info-window {
    margin: 0px auto;
    margin-top: 100px;
    background-color: var(--card-background);
    border-radius: 5px;
    width: 75%;
    padding: 0px 60px;
    text-align: left;
    padding-bottom: 30px;
}

#info-window #head #title {
    font-size: 30px;
    display: inline-flex;
    justify-content: center;
}

#info-window #head #title img {
    width: 30px;
    margin-left: 10px;
}

#info-window #head #sub-title {
    color: grey;
    font-size: 12.5px;
}

#info-window #content {
    padding: 0px;
}

#info-window #content section {
    font-size: 13px;
    color: grey;
}

#info-window #content section p {
    font-size: 28px;
    color: white;
    margin-bottom: 0;
}

#info-window #content section ul li a, #info-window #content #about-partner a {
    color: #5865F2;
    text-decoration: underline;
    transition: 100ms ease;
}

#info-window #content section ul li a:hover, #info-window #content #about-partner a {
    color: #7783ff;
    cursor: pointer;
}

#info-window #content #list {
    width: 400px;
}


#info-window #content #about-partner {
    color: grey;
}

#info-window #content #list #partner-card {
    display: inline-flex;
    align-items: center;
    width: 400px;
    background-color: #303336;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

#info-window #content #list #partner-card #info .about {
    color: gray;
    font-size: 13px;
    text-align: justify;
}

#info-window #content #list #partner-card img {
    height: 100px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.4);
    margin-right: 15px;
}

button#req{
    background-color: #5865f2;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 12px;
    font-size: 18px;
    border-radius: 5px;
    transition: .1s ease;
    border-style: none;
    margin-bottom: 10px;
    margin-right: 0;
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
}

@media only screen and (max-width: 720px) {
    #info-window {
        margin: unset 10px;
        padding: 20px;
        margin-top: 50px;
    }

    #info-window #content #list #partner-card {
        transform: scale(0.7);
        margin-left: -70px;
    }
}