@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap');

.CARD_DUMMY_BG {
    position: relative;
    top: 0px;
    background-image: linear-gradient(90deg, #23272A 20%, rgb(58, 67, 83) 50%, #23272A 100%); 
    animation: LoadingBackground infinite 4s;
    z-index: 100px;
    color: transparent;
    height: 150%;
    width: 150%;
}

@keyframes LoadingBackground {
    0% {
        left: -200%;
    }
    100% {
        left: 200%;
    }
}

.card {
    background-color: #23272A;
    height: 350px;
    width: 300px;
    color: #fff;
    border-radius: 5px;
    transition-duration: 0.3s;
    position: relative;
    bottom: 0px;
    overflow: hidden;
    margin-bottom: 10px;
    border-width: 0px;
    overflow: hidden;
    margin-top: 30px;
    z-index: 99;
    border-color: transparent;
}

.card[style="border-color: transparent;"] {
    border-style: solid;
    border-width: 0px;
}

#edit{
    border: 1px solid black;
    float: left;
    margin-right: 30px;
}

.custom-card {
    border-style: solid;
    border-width: 2px;
}

.card:hover:hover, .bannerparent:hover, .iconbg:hover, .bot-infos:hover, .bot-infos *:hover, .badge:hover {
    cursor: pointer;
}

.card:hover {
    position: relative;
    bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
}

.card:hover > .bannerparent {
    transform: scale(1.05);
}

.card-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(1, 1fr);
    grid-auto-rows: 0; /* set height to 0 for autogenerated grid rows */
    overflow-y: hidden; /* hide grid items that overflow */
    justify-content: space-between;
    grid-column-gap: 30px;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% + 10px);
}

.card-group-search, .card-group-profile {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-auto-rows: minmax(200px, auto);
    justify-content: space-between;
    grid-column-gap: 30px;
    padding-top: 10px;
    height: fit-content;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 555px) {
    .card-group-profile {
        justify-content: center;
        margin-left: unset;
        margin-right: unset;
        width: 99%;
    }
}

.bannerparent {
    transition-duration: 0.3s;
}

.banner {
    width: 100%;
    transition-duration: 0.3s;
    height: 150px;
    overflow: hidden;
    object-fit: cover;
}

.shortdesc {
    height: 80px;
    overflow: hidden;
}

.iconbg {
    width: 70px;
    height: 70px;
    position: relative;
    top: -11.5%;
    left: 10%;
}

.badge {
    float: right;
    position: absolute;
    right: 5px;
    top: 45%;
}

.icon {
    width: 100%;
    border-radius: 10px;
    margin: 0px;
    border-style: solid;
    border-width: 3px;
    border-color: #23272A;
    background-color: #23272A;
}

.shortdesc {
    color: #99AAB5;
    font-size: 14px;
    margin-top: -20px;
    width: 90%;
    resize: none;
    border-style: none;
    background-color: rgba(0, 0, 0, 0);
    font-family: 'Work Sans', sans-serif;
}

.bot-infos {
    margin-top: -40px;
    font-size: 20px;
    font-family: 'Work Sans', sans-serif;
}

.name, .shortdesc, .bottom {
    margin-left: 15px;
    margin-right: 15px;
}

.shortdesc {
    padding-left: 0px;
}

.name {
    text-align: left;
}

.bottom, .viewbtn {
    font-size: 60%;
    font-weight: 400;
    color: #6c757d;
}

.viewbtn {
    position: relative;
    bottom: -5px;
}

.guilds {
    position: absolute;
    left: 5px;
    bottom: 5px;
}

.edit {
    position: absolute;
    left: 6px;
    bottom: 6px;
    color: #000;
    background-color: #FEE75C;
    padding: 2px 10px;
    border-radius: 3px;
    transition: 100ms ease;
    text-decoration: none;
    font-size: 13px;
}

.edit:hover {
    cursor: pointer;
    background-color: #FEE75Cc7;
}

.delete {
    position: absolute;
    right: 6px;
    bottom: 6px;
    color: #000;
    background-color: #ED4245;
    padding: 2px 10px;
    border-radius: 3px;
    transition: 100ms ease;
    text-decoration: none;
    font-size: 13px;
}

.delete:hover {
    cursor: pointer;
    background-color: #ED4245c7;
}

.bottom .show {
    text-align: center;
    position: relative;
    bottom: -20px;
}

.bottom .show a, .viewbtn {
    color: #6c757d;
    transition-duration: 0.3s;
}

.bottom .show a:hover, .viewbtn:hover {
    color: rgb(230, 109, 109);
}

.votes {
    position: absolute;
    right: 15px;
    bottom: 5px;
}

.guilds {
    position: absolute;
    left: 15px;
    bottom: 5px;
}

#featured {
    display: grid;
    margin: auto;
    padding: 8px;
    grid-template-columns: repeat(auto-fit, 320px);
    grid-auto-rows: minmax(200px, auto);
    grid-gap: 8px;
    justify-content: center;
}

.card svg{
    top: 12px !important;
}

@media only screen and (max-width: 720px) { 
    .card-group {
        top: 30px;
    }
}