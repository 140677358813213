@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

:root{
    --lightblue: #5865F2;
    --darkgrey: #22272c;
    --font-black: #000000;
    --font-white: #ffffff;
    --hover-grey: grey;
    --font-grey: #272727;
    --background: #343a40;
    --buttons: #00b4ff;
    --card-background: #23272A;
    --lightgrey: rgb(182, 182, 182);
}

img {
    pointer-events: none;
}

::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: #23272A;
    border-radius: 5px;
}

::placeholder {
    opacity: 0.7;
}

html {
    overflow: hidden;
}

* {
    font-family: 'Poppins', sans-serif;
}

body, html {
    background: url(https://cdn.discord-botlist.eu/pictures/bg.gif);
    font-family: 'Staatliches';
    overflow-x: hidden;
    height: 100vh;
    padding: 0px;
    margin: 0px;
}

#root{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: #fff;
}

#right{
    float: right
}

input{
    font-family: 'Work Sans', sans-serif;
    padding-bottom: 5px 10px !important;
}

#splash {
    background-color: #23272A;
    width: 100%;
    padding: 60px;
    color: #fff;
    height: fit-content;
    padding-left: 120px;
}


#splash #right img{
    height: 34vh;
    margin-top: -400px;
    margin-right: 200px;
    margin-left: 50px;
    display: block;
}

img {
    user-select: none;
}

#title {
    font-size: 50px;
}

#sub-title {
    font-size: 15px;
    color: #99AAB5;
    margin-bottom: 40px;
    margin-top: -30px;
}

#search-parent {
    width: 40%;
    border-color: var(--lightblue);
    border-style: solid;
    padding: 10px;
    border-width: 2px;
    border-radius: 10px;
    display: inline-flex;
}

svg {
    filter: invert(0%);
    height: 33px;
    width: 33px;
    vertical-align: sub;
}

#search-parent input {
    margin-left: 10px;
    width: 99%;
    outline: none;
    border-style: solid;
    border-color: transparent;
    background-color: transparent;
    color: #fff;
    font-size: 15px;
}

.tag1 {
    margin: 20px 0px;
    padding: 5px 10px;
    border-width: 2px;
    border-radius: 10px;
    border-color: var(--lightblue);
    border-style: solid;
    display: inline-flex; 
    margin-right: 10px;
    font-size: 17px;
    padding-right: 15px;
    transition-delay: 0.1s;
}

.tag1:hover {
    color: var(--lightblue);
    transition: color 200ms ease-in-out;
    cursor: pointer;
}

.tag1 svg {
    fill: #fff;
    transition: fill 200ms ease-in-out;
    vertical-align: top;
    height: 26px;
    width: 26px;
}

.tag1:hover > svg {
    fill: var(--lightblue);
}

.tag1 span {
    position: relative;
    left: 5px;
}

#lists {
    padding: 0px 140px;
    color: #fff;
    font-size: 30px;
}

#lists #headline{
    width: fit-content;
    border-bottom: 2px solid white;
    width: 300px;
    padding-bottom: 10px;
    margin-left: 10px;
}

#headline-desc{
    font-size: 20px;
    margin-top: -25px;
    margin-bottom: -25px;
    margin-left: 10px;
}

/* FOOTER ZEUG */

footer{
    margin-top: auto;
}

#footer-container{
    background-color: #23272A;
    color: white;
    margin-top: -8px;
}

#footer-container img{
    width: 100%;
}

#inside{
    width: fit-content;
    margin: auto;
}

#footer-title{
    color: var(--lightblue);
    font-size: 30px;
    text-align: left;
    border-bottom: 1px solid var(--lightblue);
    font-weight: bolder;
}

#desc-about{
    text-align: left;
    margin-top: 3px;
    font-size: 15px;
    color: var(--lightgrey);
}

#list-tile{
    font-size: 20px;
    text-align: left;
    border-bottom: 1px solid grey;
    padding-right: 50px;
    width: fit-content;
    margin-bottom: -10px;
    color: rgb(233, 233, 233);
}

#footer-container .list ul li, .list ul li a{
    text-align: left;
    text-decoration: none;
    list-style: none;
    margin-left: -20px;
    padding-bottom: 5px;
    color: var(--lightgrey);
    transition: all 0.2s ease;
}

.list ul li a:hover{
    color: white;
}

#copyrigth-and-after{
    text-align: center;
    color: var(--lightgrey);
    font-size: 10px;
}

#copyrigth-and-after img{
    width: 130px;
    margin-top: 20px;
    margin-bottom: 30px;
    pointer-events: unset !important;
}

#footer-container-center{
    display: grid;
    margin: auto;
    text-align: center;
    grid-template-columns: repeat(auto-fit, 320px);
    grid-auto-rows: minmax(200px, auto);
    grid-gap: 50px;
    justify-content: center;
}

#list-tile a, #menuul li a {
    color: #fff;
}

#ad-label{
    height: 120px;
    width: fit-content;
    background-color: #23272a;
    background-color: var(--card-background);
    margin: auto;
    text-align: center;
    margin-top: -30px;
}

#ad-label img{
    margin-top: -40px;
    height: 120px;
    margin-right: -30px;
    z-Index: -1
}

#ad-content{
    padding-top: 40px;
}

#ad-label-ad{
    color: #fff;
    float: right;
    color: grey;
    margin-top: 45px;
    background-color: #181818;
    padding: 5px;
    z-index: 100;
    position: relative;
}

#CALLBACK_LOADING {
    padding-top: 100px;
}

@media only screen and (max-width: 905px) {  
    body, html{
        padding-top: 30px;
    }

    #splash{
        padding-left: 0px
    }
    
    #title, #sub-title{
        text-align: center;
    }

    #search-parent{
        width: 80%;
        display: flex;
        margin: auto;
    }

    #tags-parent{
        width: 80%;
        text-align: center;
        margin: auto;
        margin-top: 10px;
    }

    .card-group, .card-group-search {
        grid-template-rows: repeat(6, 1fr) !important;
        width: fit-content !important;
        margin: auto;
    }

    #title-container{
        width: fit-content;
        margin: auto;
    }

    #lists{
        padding: 0px 140px;
        color: #fff;
        font-size: 30px;
        width: 300px;
        margin: auto;
        display: unset;
        margin-left: 0px;
    }

    footer #desc-about img{
        display: none;
    }

    #footer-box{
        max-width: 90%;
        margin: auto;
    }

    footer-title{
        font-size: 25px;
    }

    #inside {
        width: 280px;
    }

    #ad-label, #ad-label img{
        width: calc(100% - 10px);
        margin: none !important;
        height: fit-content;
        background: none;
    }

    #ad-label-ad{
        bottom: 0;
        margin-top: -10px;
    }

    #tags-parent .tag1{
        margin: 10px 10px;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    #footer-container .list ul li, .list ul li a {
        margin-left: 0px;  
    }

    #page-thing{
        width: fit-content !important;
    }

    #right-page-thing {
        padding: 5px !important;
        margin: 5px !important;
        margin-top: 13px !important;
    }

    #left-page-thing, #right-page-thing {
        padding: 22px 5px !important;
    }

    #before-page-thing a, #after-page-thing a {
        display: none;
    }

    #current-page-thing {
        margin: 5px !important;
        margin: 10px !important;
    }

    .list ul {
        padding-left: 0px;
    }

    #footer-container-center {
        grid-gap: 0px;
    }
}

#search-sgt {
    width: calc(40% + 22px);
    margin-top: 10px;
    position: sticky;
    z-index: 1;
}

#search-sgt div {
    border: solid 1.5px #23272a;
    padding: 5px 10px;
    transition: 100ms ease;
    background-color: #3e3e3e;
    display: inline-flex;
    align-items: center;
    width: 100%;
}

#search-sgt div img {
    width: 30px;
    margin-right: 10px;
    border-radius: 5px;
}

#search-sgt div:hover {
    cursor: pointer;
    background-color: rgb(81, 81, 81);
}

@media only screen and (max-width: 555px) {
    #search-sgt {
        padding-left: 25px;
        width: calc(80% + 23px);
    }

    #search-sgt div {
        width: calc(90% + 8px);
    }
}

input, textarea{
    -webkit-user-select: text;
    user-select: text;
    -moz-user-select: text;
}

#copyrigth-and-after a {
    color: #5865F2;
    text-decoration: none;
    transition: 100ms ease;
}

#copyrigth-and-after a:hover {
    color: #737efa;
}