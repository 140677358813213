@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@500&display=swap);
:root{
    --lightblue: #5865F2;
    --darkgrey: #22272c;
    --font-black: #000000;
    --font-white: #ffffff;
    --hover-grey: grey;
    --font-grey: #272727;
    --background: #343a40;
    --buttons: #00b4ff;
    --card-background: #23272A;
    --lightgrey: rgb(182, 182, 182);
}

img {
    pointer-events: none;
}

::-webkit-scrollbar {
    background-color: transparent;
    width: 6px;
}

::-webkit-scrollbar-thumb {
    background: #23272A;
    border-radius: 5px;
}

::-webkit-input-placeholder {
    opacity: 0.7;
}

:-ms-input-placeholder {
    opacity: 0.7;
}

::placeholder {
    opacity: 0.7;
}

html {
    overflow: hidden;
}

* {
    font-family: 'Poppins', sans-serif;
}

body, html {
    background: url(https://cdn.discord-botlist.eu/pictures/bg.gif);
    font-family: 'Staatliches';
    overflow-x: hidden;
    height: 100vh;
    padding: 0px;
    margin: 0px;
}

#root{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 100vh;
    color: #fff;
}

#right{
    float: right
}

input{
    font-family: 'Work Sans', sans-serif;
    padding-bottom: 5px 10px !important;
}

#splash {
    background-color: #23272A;
    width: 100%;
    padding: 60px;
    color: #fff;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-left: 120px;
}


#splash #right img{
    height: 34vh;
    margin-top: -400px;
    margin-right: 200px;
    margin-left: 50px;
    display: block;
}

img {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

#title {
    font-size: 50px;
}

#sub-title {
    font-size: 15px;
    color: #99AAB5;
    margin-bottom: 40px;
    margin-top: -30px;
}

#search-parent {
    width: 40%;
    border-color: #5865F2;
    border-color: var(--lightblue);
    border-style: solid;
    padding: 10px;
    border-width: 2px;
    border-radius: 10px;
    display: -webkit-inline-flex;
    display: inline-flex;
}

svg {
    -webkit-filter: invert(0%);
            filter: invert(0%);
    height: 33px;
    width: 33px;
    vertical-align: sub;
}

#search-parent input {
    margin-left: 10px;
    width: 99%;
    outline: none;
    border-style: solid;
    border-color: transparent;
    background-color: transparent;
    color: #fff;
    font-size: 15px;
}

.tag1 {
    margin: 20px 0px;
    padding: 5px 10px;
    border-width: 2px;
    border-radius: 10px;
    border-color: #5865F2;
    border-color: var(--lightblue);
    border-style: solid;
    display: -webkit-inline-flex;
    display: inline-flex; 
    margin-right: 10px;
    font-size: 17px;
    padding-right: 15px;
    transition-delay: 0.1s;
}

.tag1:hover {
    color: #5865F2;
    color: var(--lightblue);
    transition: color 200ms ease-in-out;
    cursor: pointer;
}

.tag1 svg {
    fill: #fff;
    transition: fill 200ms ease-in-out;
    vertical-align: top;
    height: 26px;
    width: 26px;
}

.tag1:hover > svg {
    fill: #5865F2;
    fill: var(--lightblue);
}

.tag1 span {
    position: relative;
    left: 5px;
}

#lists {
    padding: 0px 140px;
    color: #fff;
    font-size: 30px;
}

#lists #headline{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-bottom: 2px solid white;
    width: 300px;
    padding-bottom: 10px;
    margin-left: 10px;
}

#headline-desc{
    font-size: 20px;
    margin-top: -25px;
    margin-bottom: -25px;
    margin-left: 10px;
}

/* FOOTER ZEUG */

footer{
    margin-top: auto;
}

#footer-container{
    background-color: #23272A;
    color: white;
    margin-top: -8px;
}

#footer-container img{
    width: 100%;
}

#inside{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
}

#footer-title{
    color: #5865F2;
    color: var(--lightblue);
    font-size: 30px;
    text-align: left;
    border-bottom: 1px solid #5865F2;
    border-bottom: 1px solid var(--lightblue);
    font-weight: bolder;
}

#desc-about{
    text-align: left;
    margin-top: 3px;
    font-size: 15px;
    color: rgb(182, 182, 182);
    color: var(--lightgrey);
}

#list-tile{
    font-size: 20px;
    text-align: left;
    border-bottom: 1px solid grey;
    padding-right: 50px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: -10px;
    color: rgb(233, 233, 233);
}

#footer-container .list ul li, .list ul li a{
    text-align: left;
    text-decoration: none;
    list-style: none;
    margin-left: -20px;
    padding-bottom: 5px;
    color: rgb(182, 182, 182);
    color: var(--lightgrey);
    transition: all 0.2s ease;
}

.list ul li a:hover{
    color: white;
}

#copyrigth-and-after{
    text-align: center;
    color: rgb(182, 182, 182);
    color: var(--lightgrey);
    font-size: 10px;
}

#copyrigth-and-after img{
    width: 130px;
    margin-top: 20px;
    margin-bottom: 30px;
    pointer-events: unset !important;
}

#footer-container-center{
    display: grid;
    margin: auto;
    text-align: center;
    grid-template-columns: repeat(auto-fit, 320px);
    grid-auto-rows: minmax(200px, auto);
    grid-gap: 50px;
    -webkit-justify-content: center;
            justify-content: center;
}

#list-tile a, #menuul li a {
    color: #fff;
}

#ad-label{
    height: 120px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #23272a;
    background-color: #23272A;
    background-color: var(--card-background);
    margin: auto;
    text-align: center;
    margin-top: -30px;
}

#ad-label img{
    margin-top: -40px;
    height: 120px;
    margin-right: -30px;
    z-Index: -1
}

#ad-content{
    padding-top: 40px;
}

#ad-label-ad{
    color: #fff;
    float: right;
    color: grey;
    margin-top: 45px;
    background-color: #181818;
    padding: 5px;
    z-index: 100;
    position: relative;
}

#CALLBACK_LOADING {
    padding-top: 100px;
}

@media only screen and (max-width: 905px) {  
    body, html{
        padding-top: 30px;
    }

    #splash{
        padding-left: 0px
    }
    
    #title, #sub-title{
        text-align: center;
    }

    #search-parent{
        width: 80%;
        display: -webkit-flex;
        display: flex;
        margin: auto;
    }

    #tags-parent{
        width: 80%;
        text-align: center;
        margin: auto;
        margin-top: 10px;
    }

    .card-group, .card-group-search {
        grid-template-rows: repeat(6, 1fr) !important;
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
        margin: auto;
    }

    #title-container{
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        margin: auto;
    }

    #lists{
        padding: 0px 140px;
        color: #fff;
        font-size: 30px;
        width: 300px;
        margin: auto;
        display: unset;
        margin-left: 0px;
    }

    footer #desc-about img{
        display: none;
    }

    #footer-box{
        max-width: 90%;
        margin: auto;
    }

    footer-title{
        font-size: 25px;
    }

    #inside {
        width: 280px;
    }

    #ad-label, #ad-label img{
        width: calc(100% - 10px);
        margin: none !important;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        background: none;
    }

    #ad-label-ad{
        bottom: 0;
        margin-top: -10px;
    }

    #tags-parent .tag1{
        margin: 10px 10px;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    #footer-container .list ul li, .list ul li a {
        margin-left: 0px;  
    }

    #page-thing{
        width: -webkit-fit-content !important;
        width: -moz-fit-content !important;
        width: fit-content !important;
    }

    #right-page-thing {
        padding: 5px !important;
        margin: 5px !important;
        margin-top: 13px !important;
    }

    #left-page-thing, #right-page-thing {
        padding: 22px 5px !important;
    }

    #before-page-thing a, #after-page-thing a {
        display: none;
    }

    #current-page-thing {
        margin: 5px !important;
        margin: 10px !important;
    }

    .list ul {
        padding-left: 0px;
    }

    #footer-container-center {
        grid-gap: 0px;
    }
}

#search-sgt {
    width: calc(40% + 22px);
    margin-top: 10px;
    position: -webkit-sticky;
    position: sticky;
    z-index: 1;
}

#search-sgt div {
    border: solid 1.5px #23272a;
    padding: 5px 10px;
    transition: 100ms ease;
    background-color: #3e3e3e;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

#search-sgt div img {
    width: 30px;
    margin-right: 10px;
    border-radius: 5px;
}

#search-sgt div:hover {
    cursor: pointer;
    background-color: rgb(81, 81, 81);
}

@media only screen and (max-width: 555px) {
    #search-sgt {
        padding-left: 25px;
        width: calc(80% + 23px);
    }

    #search-sgt div {
        width: calc(90% + 8px);
    }
}

input, textarea{
    -webkit-user-select: text;
    -ms-user-select: text;
        user-select: text;
    -moz-user-select: text;
}

#copyrigth-and-after a {
    color: #5865F2;
    text-decoration: none;
    transition: 100ms ease;
}

#copyrigth-and-after a:hover {
    color: #737efa;
}
.CARD_DUMMY_BG {
    position: relative;
    top: 0px;
    background-image: linear-gradient(90deg, #23272A 20%, rgb(58, 67, 83) 50%, #23272A 100%); 
    -webkit-animation: LoadingBackground infinite 4s; 
            animation: LoadingBackground infinite 4s;
    z-index: 100px;
    color: transparent;
    height: 150%;
    width: 150%;
}

@-webkit-keyframes LoadingBackground {
    0% {
        left: -200%;
    }
    100% {
        left: 200%;
    }
}

@keyframes LoadingBackground {
    0% {
        left: -200%;
    }
    100% {
        left: 200%;
    }
}

.card {
    background-color: #23272A;
    height: 350px;
    width: 300px;
    color: #fff;
    border-radius: 5px;
    transition-duration: 0.3s;
    position: relative;
    bottom: 0px;
    overflow: hidden;
    margin-bottom: 10px;
    border-width: 0px;
    overflow: hidden;
    margin-top: 30px;
    z-index: 99;
    border-color: transparent;
}

.card[style="border-color: transparent;"] {
    border-style: solid;
    border-width: 0px;
}

#edit{
    border: 1px solid black;
    float: left;
    margin-right: 30px;
}

.custom-card {
    border-style: solid;
    border-width: 2px;
}

.card:hover:hover, .bannerparent:hover, .iconbg:hover, .bot-infos:hover, .bot-infos *:hover, .badge:hover {
    cursor: pointer;
}

.card:hover {
    position: relative;
    bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    box-shadow: 0px 0px 10px 5px rgba(0,0,0,0.5);
}

.card:hover > .bannerparent {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
}

.card-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(1, 1fr);
    grid-auto-rows: 0; /* set height to 0 for autogenerated grid rows */
    overflow-y: hidden; /* hide grid items that overflow */
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-column-gap: 30px;
    padding-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
    width: calc(100% + 10px);
}

.card-group-search, .card-group-profile {
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-auto-rows: minmax(200px, auto);
    -webkit-justify-content: space-between;
            justify-content: space-between;
    grid-column-gap: 30px;
    padding-top: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
}

@media only screen and (max-width: 555px) {
    .card-group-profile {
        -webkit-justify-content: center;
                justify-content: center;
        margin-left: unset;
        margin-right: unset;
        width: 99%;
    }
}

.bannerparent {
    transition-duration: 0.3s;
}

.banner {
    width: 100%;
    transition-duration: 0.3s;
    height: 150px;
    overflow: hidden;
    object-fit: cover;
}

.shortdesc {
    height: 80px;
    overflow: hidden;
}

.iconbg {
    width: 70px;
    height: 70px;
    position: relative;
    top: -11.5%;
    left: 10%;
}

.badge {
    float: right;
    position: absolute;
    right: 5px;
    top: 45%;
}

.icon {
    width: 100%;
    border-radius: 10px;
    margin: 0px;
    border-style: solid;
    border-width: 3px;
    border-color: #23272A;
    background-color: #23272A;
}

.shortdesc {
    color: #99AAB5;
    font-size: 14px;
    margin-top: -20px;
    width: 90%;
    resize: none;
    border-style: none;
    background-color: rgba(0, 0, 0, 0);
    font-family: 'Work Sans', sans-serif;
}

.bot-infos {
    margin-top: -40px;
    font-size: 20px;
    font-family: 'Work Sans', sans-serif;
}

.name, .shortdesc, .bottom {
    margin-left: 15px;
    margin-right: 15px;
}

.shortdesc {
    padding-left: 0px;
}

.name {
    text-align: left;
}

.bottom, .viewbtn {
    font-size: 60%;
    font-weight: 400;
    color: #6c757d;
}

.viewbtn {
    position: relative;
    bottom: -5px;
}

.guilds {
    position: absolute;
    left: 5px;
    bottom: 5px;
}

.edit {
    position: absolute;
    left: 6px;
    bottom: 6px;
    color: #000;
    background-color: #FEE75C;
    padding: 2px 10px;
    border-radius: 3px;
    transition: 100ms ease;
    text-decoration: none;
    font-size: 13px;
}

.edit:hover {
    cursor: pointer;
    background-color: #FEE75Cc7;
}

.delete {
    position: absolute;
    right: 6px;
    bottom: 6px;
    color: #000;
    background-color: #ED4245;
    padding: 2px 10px;
    border-radius: 3px;
    transition: 100ms ease;
    text-decoration: none;
    font-size: 13px;
}

.delete:hover {
    cursor: pointer;
    background-color: #ED4245c7;
}

.bottom .show {
    text-align: center;
    position: relative;
    bottom: -20px;
}

.bottom .show a, .viewbtn {
    color: #6c757d;
    transition-duration: 0.3s;
}

.bottom .show a:hover, .viewbtn:hover {
    color: rgb(230, 109, 109);
}

.votes {
    position: absolute;
    right: 15px;
    bottom: 5px;
}

.guilds {
    position: absolute;
    left: 15px;
    bottom: 5px;
}

#featured {
    display: grid;
    margin: auto;
    padding: 8px;
    grid-template-columns: repeat(auto-fit, 320px);
    grid-auto-rows: minmax(200px, auto);
    grid-gap: 8px;
    -webkit-justify-content: center;
            justify-content: center;
}

.card svg{
    top: 12px !important;
}

@media only screen and (max-width: 720px) { 
    .card-group {
        top: 30px;
    }
}
/* #card-group {
    display: grid;
    width: 90%;
    grid-template-columns: repeat(auto-fit, 500px);
    grid-auto-rows: minmax(120px, auto);
    grid-gap: 40px;
    justify-content: left;
}

.card {
    background-color: #23272A;
    height: 120px;
    width: 100%;
    color: #fff;
    border-radius: 5px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition-duration: 0.3s;
    position: relative;
    bottom: 0px;
    overflow: hidden;
    border-width: 0px;
    overflow: hidden;
    display: flex;
}

.card img {
    width: 120px;
}

.department {
    position: absolute;
    bottom: -10px;
    right: 10px;
    color: gray;
}

.name {
    font-size: 18px;
}

#staffs {
    padding-left: 100px;
    width: 100%;
}

#staffs h2 {
    color: white;
    margin-top: 60px;
    font-weight: 100;
    text-decoration: underline;
} */
.box {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #1b1f21;
  border-radius: 10px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
}

.box iframe {
  margin: 50px 5%;
  border: 0px solid;
}

#infos-general {
  max-width: calc(1200px - 6%);
  padding: 20px 3%;
  padding-top: 50px;
  z-index: 2;
  position: relative;
  padding-bottom: 0px;
}

#infos-general #head {
  display: -webkit-flex;
  display: flex;
}

#infos-general #head #pfp {
  width: 180px;
  height: 180px;
  position: relative;
  left: -90px;
  top: -90px;
  margin-right: -50px;
  margin-bottom: -60px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  background-color: #1b1f21;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
}

#infos-general #head {
  position: relative;
  z-index: 4;
}

#infos-general #head div #name {
  position: relative;
  font-size: 25px;
  top: -22px;
}

#infos-general #head div #shortdesc {
  position: relative;
  font-size: 15px;
  top: -22px;
  color: gray;
}

#infos-general #head div #name .bot-badge {
  position: relative;
  top: 8px;
  left: 3px;
  width: 28px;
}

#infos-general #head div #tags {
  position: relative;
  top: -10px;
  width: 900px;
  display: block;
  word-wrap: break-word;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#infos-general #head div #tags .tag {
  color: rgb(208, 208, 208);
  background-color: rgb(99, 99, 99);
  margin-right: 5px;
  padding: 2px 6px;
  border-radius: 3px;
  white-space: nowrap;
  transition: 100ms ease;
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
}

#infos-general #buttons {
  display: block;
  word-wrap: break-word;
  width: 900px;
}

#infos-general #buttons .button {
  white-space: nowrap;
  display: inline-block;
  margin-bottom: 5px;
}

#infos-general #head div #tags .tag:hover {
  color: rgba(208, 208, 208, 0.75);
  background-color: rgba(99, 99, 99, 0.75);
  cursor: pointer;
}

#infos-general #devs {
  word-wrap: break-word;
}

#infos-general #devs .user {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: rgb(208, 208, 208);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 0px 5px;
  border-radius: 5px;
  transition: 100ms ease;
  margin-right: 5px;
  font-size: 14px;
  float: left;
  background-color: rgb(99, 99, 99);
  white-space: nowrap;
  /* display: inline-block; */
  margin-bottom: 5px;
}

#infos-general #devs .user:hover {
  color: rgba(208, 208, 208, 0.75);
  background-color: rgba(99, 99, 99, 0.75);
  cursor: pointer;
}

#infos-general #devs .user #badges {
  padding: 7px;
  padding-right: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

#infos-general #devs .user #badges svg {
  position: relative;
  width: 20px;
  height: 20px;
  -webkit-transform: scale(0.9);
          transform: scale(0.9);
}

#infos-general #buttons {
  clear: both;
  padding: 20px 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

#infos-general #buttons .button {
  background-color: #5865F2;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px 12px;
  font-size: 18px;
  border-radius: 5px;
  margin-right: 10px;
  transition: 100ms ease;
}

#infos-general #buttons .button:hover {
  cursor: pointer;
  background-color: #5865f2c7;
}

#infos-general #buttons #report {
  background-color: #ED4245;
}

#infos-general #buttons #report:hover {
  background-color: #ED4245c7;
}

#infos-general #buttons #edit {
  color: #000;
  background-color: #FEE75C;
}

#infos-general #buttons #edit:hover {
  background-color: #FEE75Cc7;
}

#infos-general #buttons .additional {
  background-color: rgba(128, 128, 128, 0.685);
  color: #fff;
}

#infos-general #buttons .additional:hover {
  background-color: #8080808a;
}

#bannerImg {
  width: 100%;
  margin-left: -57px;
  margin-top: -50px;
  height: 150px;
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  left: 57px;
  image-rendering: optimizeQuality;
}

#bannerGradient {
  width: 100%;
  margin-left: -57px;
  left: 57px;
  margin-top: -50px;
  height: 150px;
  position: absolute;
  z-index: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-image: linear-gradient(transparent, #1b1f21);
}

#infos-general #stats {
  color: gray;
  font-size: 15px;
  text-align: right;
  position: relative;
  top: -5px;
  right: -40px;
}

.preview {
  width: 90%;
}

#content-box{
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  max-width: 1197px;
  margin: auto;
  margin-bottom: 100px;
  padding: 10px;
}

.outdated{
  background-color: rgba(255, 0, 0, 0.418);
  border-left: 3px solid red;
}
.degraded{
  background-color: rgb(160, 160, 40);
  border-left: 3px solid yellow;
}

.news{
  background-color: #1b1f21;
  border-left: 3px solid #50595e;
  font-size: 20px;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 75%);
}

#unapproved {
  width: 80%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin-left: 10%;
  margin-right: 10%;
  background-color: #BC3131;
  border: 3px red solid;
  border-radius: 10px;
  color: white;
  text-align: center;
  margin-bottom: 100px;
}

#cert-req-parent {
  width: 100vw;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 25px;
  background-color: #5865F2;
  z-index: 1000;
  color: #fff;
  text-align: center;
  padding: 8px 0px;
}

#cert-req {
  border: solid 1px #fff;
  color: #fff;
  transition: 200ms ease;
}

#cert-req:hover {
  border-color: #ff9797;
  color: #ff9797;
}

@media only screen and (max-width: 555px) {
  .box {
    width: 85%;
  }

  #infos-general {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  #infos-general #head #pfp {
    left: 0px;
    top: -40px;
    width: 100px;
    height: 100px;
  }

  #infos-general #head {
    display: block;
  }

  #infos-general #head div #name {
    display: block;
    margin-top: 50px;
  }

  #infos-general #head div #shortdesc {
    display: block;
    margin-top: -25px;
    margin-bottom: -25px;
  }

  #infos-general #head div #tags, #infos-general #buttons {
    display: block;
    margin-bottom: 10px;
    width: 300px;
  }

  #infos-general #buttons .button {
    width: 100%;
    margin-left: -15px;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }

  #infos-general #stats {
    left: -5px;
  }

  #longdesc-frame {
    width: 90%;
  }

  #cert-req-parent {
    height: 60px;
  }
}
#p404 #CONTENT h1 {
    color: #fff;
    text-align: center;
    margin-top: 100px;
}

#p404 #CONTENT h3 {
    color: gray;
    text-align: center;
}

#p404 #BOX {
    display: flow-root;
    height: 200px;
}

#p404 #BOX strong {
    float: left;
    margin-left: 30px;
    font-size: 50px;
    font-weight: bold;
    margin-top: 40px;
}

#p404 #BOX section {
    float: left;
    margin-left: -95px;
    font-size: 20px;
    color: gray;
    margin-top: 100px;
}

#p404 #BOX p {
    text-align: left;
    margin-left: 20px;
    margin-top: 160px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    float: left;
    position: relative;
    left: -355px;
}

@media only screen and (max-width: 555px) {
    #p404 #CONTENT {
        width: 15%;
        margin-bottom: -90px;
    }

    #p404 #BOX strong {
        margin-top: 35px;
        margin-left: 10px;
    }

    #p404 #BOX section {
        margin-left: -95px;
        font-size: 15px;
    }

    #p404 #BOX p {
        left: -10px;
        top: -125px;
    }

    #p404 #BOX {
        margin-left: -115px;
        margin-bottom: -40px;
    }
}

#all-container{
  display: grid;
  grid-template-columns: 50% 50%;
  transition: all 1s ease;
}

.ace_line, .ace_text, .ace_meta, .ace_entity, .ace_xml, .ace_indent-guide{
  font: 12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace !important;
}

#form-container{
  background-color: #272727;
  margin: auto;
  margin-top: 200px;
  width: 90%;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 100px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

#form-container-title{
  font-size: 30px;
  text-align: center;
  padding-top: 10px;
  margin: auto;
  width: calc(80% + 20px);
  border-bottom: 2px solid grey;
  margin-bottom: 40px;
}

#form-container form{
  display: grid;
  margin: auto;
  width: 80%;
  padding-bottom: 30px;
}

#longdescription{
  width: 100% !important;
  min-height: 300px;
}

#longdescription textarea{
  min-width: unset !important;
  padding: 0px !important;
}

#form-container form label{
  font-size: 20px;
  font-weight: 300;
}

#form-container form input::-webkit-input-placeholder{
  color: rgb(219, 219, 219);
}

#form-container form input:-ms-input-placeholder{
  color: rgb(219, 219, 219);
}

#form-container form input::placeholder{
  color: rgb(219, 219, 219);
}

#form-container form input, #form-container form textarea{
  height: 30px;
  display: block;
  margin-bottom: 10px;
  margin-top: 2px;
  background-color: var(--font-grey);
  border: 1px solid grey;
  padding: 23px 10px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  border-radius: 10px;
}

#form-container form input:focus{
  border-color: #0192cf;
  transition: all 0.3s;
  -webkit-transform: scale(1.01);
          transform: scale(1.01);
}

input#news{
  display: none !important;
}

#right-cont{
  border: 3px solid var(--font-grey);
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 90%;
  margin-top: 200px;
  transition: all 1s ease;
  overflow: hidden;
  padding-bottom: 20px;
}

#form-container form button{
  height: 40px;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color:#2c2c2c;
  border: 0px solid var(--buttons);
  padding: 6px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 600;
  padding-left: 10px;
  border-radius: 10px;
  transition: 0.3s ease;
  cursor: pointer;
}

#form-container form button:hover{
  margin-top: 5px;
  box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.75);
  background-color: #00b4ff;
  margin-bottom: 15px;
}

#form-container form input:focus{
  outline: none;
}

#form-container #box{
  background-color: var(--font-grey);
  width: clamp(400px, 90%, 100%) !important;
  margin-top: 50px;
  padding: 20px;
  border-radius: 10px;
  color: var(--font-white);
  margin-right: 30px;
  transition: 0.3s ease;
  box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.75);
}
  
#box img{
  height: 100px;
  margin-top: -40px;
  margin-left: 10px;
  box-shadow: 0px 0px 38px -10px rgba(0,0,0,0.75);
  border-radius: 20px;
  object-fit: cover;
}
  
.bot-name{
  font-weight: bolder;
  font-size: 30px;
}
  
.disc{
  color: grey;
  display: inline;
  font-size: 20px;
}
  
.description{
  color: rgb(192, 192, 192);
  margin: auto;
  margin-top: 20px;
  width: 90%;
  font-weight: bolder;
}
  
#button{
  height: 30px;
  width: 90%;
  text-align: center;
  padding: 5px;
  margin: auto;
  padding-top: 10px;
  margin-top: 10px;
  background-color: #343a40;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
  
#button:hover{
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 10px;
  width: 90%;
  background-color: #464d53;
  height: 35px !important;
}

#error{
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 10px;
  display: block;
  margin-top: 20px;
  background-color: rgb(255, 0, 0,0.3);
  border: 2px solid rgb(255, 0, 0,0.5);
  border-radius: 10px;
}

button:focus{
  outline: none;
}

.tag-box{
  display: -webkit-inline-flex;
  display: inline-flex;
  margin-left: 10px;
  background-color: #343a40;
  border-radius: 10px;
  padding: 10px;
}

#tags-title{
  background-color: #343a40;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  margin-left: 40px;
}

textarea{
  min-height: 100px;
  display: block;
  margin-bottom: 10px;
  margin-top: 2px;
  background-color: var(--font-grey);
  border: 1px solid grey;
  padding: 6px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  padding-left: 10px;
  border-radius: 10px;
  overflow-y: hidden;
  outline: none;
  width: 95%;
}

#submit:hover{
  bottom: 0px !important;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 50;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  -webkit-animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
          animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 70px;
  height: 70px;
  border-radius: 80%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  margin-top: -40px;
  margin-bottom: 10px;
  box-shadow: inset 0px 0px 0px #7ac142;
  -webkit-animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s;
          animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s;
}

.checkmark__check {
  -webkit-transform-origin: 50% 50%;
          transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  -webkit-animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
          animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@-webkit-keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@-webkit-keyframes scale {
  0%, 100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 2);
            transform: scale3d(1.1, 1.1, 2);
  }
}
@keyframes scale {
  0%, 100% {
    -webkit-transform: none;
            transform: none;
  }
  50% {
    -webkit-transform: scale3d(1.1, 1.1, 2);
            transform: scale3d(1.1, 1.1, 2);
  }
}
@-webkit-keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

#color {
  width: 300px;
}

#right-cont {
  background-color: rgb(59, 59, 59);
}

#form-container-inputs form #banner {
  width: 97%;
}

@media only screen and (max-width: 555px) {
  #all-container {
    display: block;
  }

  #right-cont {
    width: 100%;
    background-color: transparent;
    border-color: transparent;
  }

  #form-container-inputs form input, #form-container-inputs form #banner {
    width: 100%;
  }

  #form-container {
    margin-top: 70px;
  }

  #right-cont {
    margin-top: -80px;
  }
}
#mobile-menu{
  display: none;
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  overflow-x: hidden;
  background-color: #ffffff;
}

header{
  height: 700px;
}

#login-prompt{
  margin-top: -10px;
}

#brand-name{
  color: var(--font-white);
  font-size: 25px; 
  margin-top: -67px;
  margin-left: 80px;
  font-weight: 400;
}

#brand-logo{
  height: 50px;
  padding: 24px;
  border-radius: 100%;
}

#header-menu{
  height: 100px;
  background-color: #23272A;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}



#header-menu ul{
  padding-right: 30px;
  padding-top: 0px;
  z-index: 12;
  position: relative;
  margin-top: 0px;
}

#hover-menu ul{
  padding: 20px !important;
}

#header-menu ul svg, #header-menu ul img{
  height: 28px;
  vertical-align: text-bottom;
  width: 20px;
  margin-top: 10px;
  padding-right: 5px;
}


#header-menu ul li{
  display: inline;
  float: right;
  list-style: none;
  padding: 0px;
  margin-top: -50px;
}



#header-menu ul li a{
  text-decoration: none;
  color: var(--font-white);
  font-size: 23px;
  transition: 0.2s;
  fill: white;
  width: 100%;
  display: block;
  padding: 10px 0px 15px 20px;
}

#hover-menu-ul a{
  background-color: white;
  font-size: 20px !important;
  padding-bottom: 10px;
}


#hover-menu-ul a:hover{
  background-color: var(--hover-grey) !important;
  color: white !important;
  fill: white !important;
}

#header-menu ul li a:hover{
  color: var(--hover-grey);
  fill: var(--hover-grey) !important;
}

#hover-menu-ul a:first-child{
  border-radius: 10px 10px 0px 0px;
}

#hover-menu-ul a:last-child{
  border-radius: 0px 0px 10px 10px;
}

#login{
  display: block;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  height: 30px;
}

#header-hover-thing{
  height: 50px;
}

#header-hover-thing:hover ~ #hover-menu{
  margin-right: -70px;
  display: block;
}

#hover-menu-ul #logout{
  color: red !important;
}

#hover-menu:hover{
  display: block;
}

#hover-menu{
  /* background-color: white; */
  display: none;
  position: absolute;
  margin-right: 60px !important;
  border-radius: 10px;
  margin-left: -30px;
  padding: 0px;
  /* padding-bottom: 20px; */
}

#hover-menu-ul a{
  color: black !important;
}

.header-login-img{
  height: auto !important;
  width: 30px !important;
  border-radius: 10000px;
  padding: 0px !important;
}  

@media only screen and (max-width: 905px){
  #mobile-menu{
    display: block;
    height: 0px;
    position: fixed;
    width: 100%;
    margin-bottom: 100px;
    z-index: 1001;
    margin-top: -60px;
  }

  #hamburger{
    float: right;
    margin-top: -50px;
    margin-right: 30px;
  }

  #branding-etc{
    background-color: #23272A;
  }

  #mobile-menu svg{
    height: 100px !important;
  }

  #menuul img{
    vertical-align: middle;
    width: 40px !important;
    margin-top: -5px;
  }

  #header-menu{
    display: none;
  }

  #brand-logo-mobile{
    height: 50px;
    border-radius: 1000px;
    margin: 20px;
    
  }

  #brand-name-mobile{
    margin-top: -65px;
  margin-left: 80px;
  padding-bottom: 23px;
  font-size: 20px;
  
  }

  #menuul{
    width: 50%;
    height: 100vh;
    background-color: #34393d;
    float: right;
    margin-top: -198px;
    z-index: 1031;
    position: relative;
    font-size: 25px;
    padding: 30px;
    margin-right: -100%;
    transition: all 0.5s ease;
  }

  #menuul svg{
    height: 30px !important;
    fill: white;
    margin-right: 10px;
    vertical-align: bottom;
  }

  #menuul a{
    text-decoration: none;
  }

  #menuul li{
    margin-top: 30px;
    list-style: none;

  }

  ul li#user{ 
    padding: 20px;
    background-color: #1f2c36;
    border-radius: 10px;
    padding-bottom: 25px;
  }

  ul li#user a div div a svg {
    position: relative;
    top: -5px;
  }
  #hover-menu{
    background-color: unset;
    width: 100%;
    font-size: 20px;
    margin-left: 0px;
  }

  #hover-menu svg{
    display: none;
  }

  #hover-menu-ul{
    margin-top: 10px;
  }

  #hover-menu-ul a{
    color: white !important;
    margin-top: 0px;
    display: block;
    background-color: unset;
  }

  #login-prompt{
    margin-top: 0px;
  }

  #hover-menu{
    margin-left: 10px;
  }

  #hover-menu-ul a:last-child{
    margin-bottom: -10px;
  }

  #branding-etc {
    padding-bottom: 10px;
  }
  
  #header-wave-svg path {
    fill: transparent;
  }

  #hover-menu-ul {
    padding-left: 0px;
    margin-left: -30px;
    background-color: gray;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 10px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 5px;
  }
} 
#sub-headline{
    font-size: 15px;
    color: grey;
}

#box-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(1, 1fr);
    -webkit-justify-content: left;
            justify-content: left;
    grid-column-gap: 30px;
    padding-top: 10px;
    height: 420px;
    width: calc(80% - 50px);
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    padding-bottom: 100px;
}

#page-thing{
    font-family: 'Work Sans', sans-serif;
    background-color: #23272A;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin:auto;
    margin-top: 50px;
    margin-bottom: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flow-root;
    border-radius: 5px;
}

#page-thing svg{
    height: 20px;
}

#left-page-thing{
    color: white;
    font-size: 30px;
    float: left;
    padding: 22px 10px;
    margin: 10px;
    background-color: #5865F2;
    border-radius: 5px;
    width:-webkit-max-content;
    width:max-content;
    cursor: pointer;
}

#before-page-thing, #current-page-thing, #after-page-thing{
    float: left;
    font-size: 30px;
    color: white;
    display: block;
    margin-top: 20px;
}

#current-page-thing{
    float: left;
    font-size: 30px;
    padding: 8px;
    padding-top: 10px;
    margin: 10px;
    background-color: #5865F2;
    border-radius: 5px;
    display: block;
    width: -webkit-max-content;
    width: max-content;
}

#right-page-thing {
    float: left;
    font-size: 30px;
    padding: 22px 10px;
    margin: 10px;
    background-color: #5865F2;
    border-radius: 5px;
    display: block;
    width: -webkit-max-content;
    width: max-content;
    cursor: pointer;
}

#before-page-thing a, #after-page-thing a{
    color: white;
    padding: 10px;
    text-decoration: none;
}

* {
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

#CONTENT {
    padding: 150px;
}

#BOX, #BOX-VOTED {
    border: solid transparent 2px;
    width: 1000px;
    border-radius: 10px;
    background-color: rgb(39, 39, 39);
    color: #fff;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5);
    margin: 0px auto;
}

#ICON {
    width: 200px;
    margin: 20px;
    border-radius: 10px;
}

#NAME {
    font-size: 25px;
    margin-top: 10px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#VOTE_BTN {
    background-color: rgb(68, 179, 127);
    color: rgb(39, 39, 39);
    /* color: #fff; */
    padding: 8px 50px;
    border: solid transparent;
    border-radius: 5px;
    font-size: 17px;
    margin: 20px;
    margin-top: 40px;
    transition: background-color 200ms ease;
}

#VOTE_BTN:hover {
    background-color: #57f28883;
    cursor: pointer;
}

#BACK_TO {
    margin-left: 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#BOX p {
    color: gray;
    transition: color 100ms ease;
}

#BACK_TO:hover {
    cursor: pointer;
    color: lightgray;
}

#BOX-VOTED {
    margin: 0px auto;
    color: gray;
}

#VOTED_NONE {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

#VOTED_NONE:hover {
    color: gray;
    cursor: default;
}

@media only screen and (max-width: 1050px) {
    #BOX, #BOX-VOTED {
        margin: auto;
        width: 95%;
        margin-top: -50px;
        margin-bottom: -120px;
    }

    #CONTENT {
        padding: 150px 0px;
    }
}

#vote-s-links a {
    color: #5865F2;
    text-decoration: none;
    transition: 100ms ease;
}

#vote-s-links a:hover {
    color: #737efa;
}
#container-apply{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    background-color: var(--card-background);
    padding: 50px;
    color: white;
    margin-top: 100px;
}

#sub{
    margin-bottom: 20px;
}

.input-desc{
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 3px;
}

.input{
    width: 97%;
    border-radius: 5px;
    background-color: #1B1C1F;
    border: 1px solid black;
    height: 20px;
    outline: none;
    padding: 10px;
    color: white;
    margin-bottom: 10px;
}

.input ::-webkit-input-placeholder{
    color: grey;
}

.input :-ms-input-placeholder{
    color: grey;
}

.input ::placeholder{
    color: grey;
}

.input[type="checkbox"]{
    width: 30px;
}

#headline-all{
    width: 80%;
    margin: auto;
    color: white;
    margin-top: 100px;
    height: 100px;
    background-color: var(--card-background);
    font-size: 30px;
    padding-top: 30px;
    text-align: center;
    margin-bottom: 50px;
}
#manage-container{
    margin-top: 50px;
    color: white;
    width: 80%;
    background-color: #2b2e31;
    margin: auto;
    margin-top: 150px;
}

#list{
    width: 300px;
    height: -webkit-max-content;
    height: max-content;
    float: left;
    margin-top: -15px;
    margin-bottom: -15px;
}

#list a{
    text-decoration: none;
}

#list ul li{
   padding: 50px;
   margin-left: -40px;
    list-style: none;
    margin-right: 30px;
    transition: all 0.3s;
    border-right: 1px solid rgb(73, 72, 72);
}
#list ul li:hover{
    background-color: grey;
}

#content-manage{
    padding-top: 30px;
    border-left: 1px solid rgb(73, 72, 72);
    margin-left: 269px;
}

.tooltip {
    background-color: grey;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;
	position: relative;
    font-style: normal;
    margin-left: 10px; 
    font-size: 12px;
    border-radius: 1000px;
    vertical-align: text-top;
}

.tooltip span[role=tooltip] {
	display: none;
}

.tooltip:hover span[role=tooltip] {
	display: block;
	position: absolute;
	bottom: 2em;
	left: -6em;
	width: 15em;
	padding: 0.7em;
	z-index: 100;
    color: white;
	background-color: #1B1C1F;
    border: 1px solid black;
	border-radius: 0.2em;
}

#button-prof{
    background-color: #1B1C1F;
    border: 1px solid black;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    margin-top: 10px;
}

#button-prof-del, #button-prof {
    width: 150px;
    /* margin-left: 10px; */
    margin-right: 15px;
}

#btns {
    display: -webkit-inline-flex;
    display: inline-flex;
}

#button-prof-del{
    background-color: #ED4245;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    margin-top: 10px;
}

#gift-cr * {
    width: 90%;
}

#button-prof-gift{
    background-color: #57F287;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    margin-top: 10px;
    width: 20%;
    margin: 10px 30px 20px;
    color: #000;
}

#button-prof:hover, #button-prof-del:hover, #button-prof-gift:hover{
    -webkit-transform: scale(110%);
            transform: scale(110%);
}

#error{
    width: 30%;
    margin: auto;
    text-align: center;
    padding: 10px;
    display: block;
    margin-top: 20px;
    background-color: rgb(255, 0, 0,0.3);
    border: 2px solid rgb(255, 0, 0,0.5);
    border-radius: 10px;
    /* margin-left: 300px; */
    display: inline-block;
    bottom: 0;
    display: none;
}

#test-container{
    border: 1px solid black;
    margin-top: 10px;
    padding: 30px;
    margin-right: 30px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flow-root;
    margin-left: 30px;
}

#test-container img{
    height: 100px;
    float: left;
}

#element{
    margin-right: 50px;
    margin-bottom: 20px;
    margin-top: -30px;
}

#test-container #desc{
    margin-left: 110px;
}

#test-container #buttons button{
    margin-right: 20px;
    padding: 10px;
    background-color: var(--card-background);
    color: white;
    border: 2px solid var(--buttons);
    cursor: pointer;
    transition: all 0.3s;
}

#test-container #buttons button:disabled{
    border: 2px solid grey !important;
}

#test-container #buttons button:disabled:hover{
    background-color: grey;
}

#others-container{
    margin-top: 100px;
    display: -webkit-flex;
    display: flex;
}

#test-container #buttons button:hover{
    background-color: var(--buttons);
}
 
.deny:hover{
    background-color: red !important;
    border: 2px solid red !important;
}

.approve:hover{
    background-color: green !important;
    border: 2px solid green !important;
}

#report-popup input[type="checkbox"]{
    height: 15px;
    width: 15px;
}

#rep-container{
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-width: 1000px;
    margin-left: 30px;
    margin-top: 30px;
    margin-right: 30px;
    display: block;
    background-color: #31363a;
    border-radius: 10px;
}

#rep-container img{
    padding: 20px;
    height: 100px;
}

#rep-container #name{
    margin-top: -125px;
    margin-left: 130px;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 75px;
}

#state{
    float: right;
    margin-top: -90px;
    margin-right: 50px;
    font-size: 15px;
    font-weight: bold;
    width: 300px;
}

#state-state{
    font-weight: 100;
}

#current{
    display: none;
}

#list #menu-mobile{
    height: unset;
}

@media only screen and (max-width: 905px){
    #content-manage {
        padding-top: 30px;
        border-left: 0px solid rgb(73, 72, 72);
        margin-left: 10px;
    }

    #list{
        width: 100%;
        height: -webkit-max-content;
        height: max-content;
        float: unset;
    }

    #list ul li {
        padding: 10px;
        margin-left: 0px;
        list-style: none;
        margin-right: 0px;
        transition: all 0.3s;
        border-right: 0px solid rgb(73, 72, 72);
    }

    #current{
        height: 40px;
        padding-left: 15px;
        border-bottom: 1px solid rgb(70, 70, 70);
        display: block;
    }

    #current svg{
        float: right;
        margin-top: -25px;
        fill: white;
        margin-right: 15px;
        height: 20px;
    }

    .title{
        margin-top: 30px;
        font-size: 20px;
    }

    #list #menu-mobile{
        height: 0px;
        overflow: hidden;
        transition: all 0.3s;
        margin-left: -30px;
    }
    #content-manage{
        padding: 20px;
        margin-left: 0px;
    }

    #rep-container{
        display: flow-root;
    }

    #state{
        margin-top: -30px;
        float: left;
        margin-left: 20px;
        width: 100%;
    }

    #state-title{
        margin-top: 10px;
    }
    
    #state-state{
        padding-bottom: 10px;
        margin-right: 10px;
    }

    #rep-container #name{
        font-size: 20px;
        margin-top: -20px;
        margin-left: 20px;
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 0px;
    }
    .input{
        width: 90% !important;
    }

    #btns{
        display: unset;
    }

    #button-prof, #button-prof-del{
        float: left;
        margin-bottom: 20px;
    }

    .guilds a{
        padding: 20px;
    }

    #manage-container #content-manage .card-container .card {
        -webkit-transform: scale(0.85);
                transform: scale(0.85);
        margin-left: -27.5px;
        margin-bottom: -60px;
    }

    #content-manage {
        margin-bottom: 40px;
    }
}

.redeembtn {
    color: #fff;
    text-decoration: none;
    background-color: #5865F2;
    padding: 3px 13px;
    border-radius: 4px;
    transition: 150ms ease;
    position: relative;
    bottom: 5px;
}

.redeembtn:hover {
    background-color: #4853c9;
}
#container-lol{
    -webkit-animation-duration: 3s;
            animation-duration: 3s;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
}

#popout{
    width: 100%;
    position: fixed;
    z-index: 500000;
    margin: auto;
    color: white;
    display: grid;
    place-items: center;
    height: 106vh;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
}

#background{
    width: 100%;
    background-color: #00000085;
    position: fixed;
    height: 200vh;
    z-index: 500000;
    margin-top: -20px;
}

#container{
    background-color: rgb(58, 58, 58);
    padding: 20px;
    border-radius: 0.5rem;
    z-index: 1000000;
    margin: 2.5rem;
    max-width: 400px;
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
}

h3{
    margin: 0;
    padding: 0;
}

button{
    background-color: rgb(0, 187, 0);
    border: 0px;
    border-radius: 0.5rem;
    color: white;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
}

#deny{
    background-color: red;
}
#user_card {
    background-color: #23272a;
    width: 40%;
    padding: 15px 20px;
    border-radius: 7px;
    margin: 0px auto;
    position: relative;
}

#avatar {
    width: 80px;
    border-radius: 100%;
    border: solid 2px;
}

#username {
    margin: 5px 0px 0px 15px;
    font-size: 20px;
    width: 100%;
}

#bio {
    color: gray;
    font-size: 15px;
    margin-top: -2px;
    width: calc(100% - 120px);
}

#pf-links {
    position: absolute;
    right: 30px;
    top: 10px;
    width: 100px;
}

.pf-link {
    color: gray;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    background-color: #1b1d1f;
    cursor: pointer;
    transition: 50ms ease;
    padding: 0px 10px;
    width: 105px;
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
}

.pf-link:hover {
    background-color: #1f2224;
}

.pf-link svg {
    fill: gray;
    width: 20px;
    margin-right: 15px;
}

#info_general {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    #user_card {
        width: 80%;
    }

    #info_general {
        display: block;
    }

    #pf-links {
        position: unset;
        width: 100%;
        margin-top: 10px;
    }

    .pf-link {
        font-size: 13px;
        margin-right: 5px;
        margin-bottom: 0px;
        width: 90px;
        -webkit-transform: scale(1);
                transform: scale(1);
    }

    .pf-link svg {
        width: 17px;
    }

    #username {
        margin-left: 0px;
    }
}
#popup {
    margin-top: 150px;
    background-color: #23272a;
    width: 50%;
    padding: 20px 30px;
    text-align: left;
}

#SELECT_BOT {
    color: #fff;
    background-color: gray;
    outline: none;
    padding: 5px 10px;
    font-size: 15px;
}

#popup button {
    background-color: #57F287;
    border-style: solid;
    border-color: transparent;
    margin-top: 10px;
    color: #000;
    padding: 5px 19px;
    transition: 200ms ease;
}

#popup button:hover {
    background-color: #57f288bf;
    cursor: pointer;
}
.ticket-header {
    font-size: 25px !important;
}

.ticket-description {
    margin-top: 20px !important;
}

#report-p_center {
    padding: 150px;
}

#report-p {
    border: solid transparent 2px;
    max-width: 1000px;
    border-radius: 10px;
    background-color: rgb(39, 39, 39);
    color: #fff;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5);
    margin: 40px auto;
    padding: 10px 20px;
    margin-top: 140px;
}

#report-p #head {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
}

#report-p #head img {
    width: 60px;
    margin-right: 15px;
    border-radius: 5px;
}

#report-p #description {
    margin-top: 40px;
    margin-bottom: 40px;
}

#report-p button {
    background-color: #ED4245;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 12px;
    font-size: 18px;
    border-radius: 5px;
    margin-right: 10px;
    transition: 100ms ease;
    border-style: none;
    margin-bottom: 10px;
    margin-right: 0px;
}

#report-p #loading-btn svg {
    width: 12px;
    height: 12px;
    padding-bottom: 5px;
}

#report-p #loading-btn {
    margin-left: 10px;
    padding-left: 80px;
}

#report-p #error-report {
    color: gray;
    font-size: 15px;
    margin-bottom: 10px;
}

#report-p button:hover {
    cursor: pointer;
    background-color: #ED4245c7;
}

@media only screen and (max-width: 555px) {
    #report_center{
        margin: 200px 70px 200px 20px;
    }

    #report-p {
        margin-left: 0;
        width: 100%;
        margin-top: -50px;
        margin-bottom: -120px;
    }
}
#cert_center {
    padding: 150px;
}

#cert {
    border: solid transparent 2px;
    width: 1000px;
    border-radius: 10px;
    background-color: rgb(39, 39, 39);
    color: #fff;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5);
    margin: 0px auto;
    padding: 10px 20px;
}

#cert #head {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
}

#cert #head img {
    width: 60px;
    margin-right: 15px;
    border-radius: 5px;
}

#cert #description {
    margin-top: 40px;
    margin-bottom: 40px;
}

#cert button {
    background-color: #5865F2;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 12px;
    font-size: 18px;
    border-radius: 5px;
    margin-right: 10px;
    transition: 100ms ease;
    border-style: none;
    margin-bottom: 10px;
    margin-right: 0px;
    color: #fff;
}

#cert #loading-btn svg {
    width: 12px;
    height: 12px;
    padding-bottom: 5px;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
}

#cert #loading-btn {
    margin-left: 10px;
    padding-left: 80px;
}

#cert-request {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
}

#cert-request img {
    width: 25px;
    -webkit-filter: brightness(0) invert(1);
            filter: brightness(0) invert(1);
    margin-left: 8px;
}

#cert #error-cert {
    color: gray;
    font-size: 15px;
    margin-bottom: 10px;
}

#cert button:hover {
    cursor: pointer;
    background-color: #5865F2c7;
}

@media only screen and (max-width: 555px) {
    #cert {
        margin-left: -135px;
        width: 480%;
        margin-top: -50px;
        margin-bottom: -120px;
    }
}
#delete-p_center {
    padding: 150px;
}

#delete-p {
    border: solid transparent 2px;
    width: 1000px;
    border-radius: 10px;
    background-color: rgb(39, 39, 39);
    color: #fff;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5);
    margin: 40px auto;
    padding: 10px 20px;
    margin-top: 140px;
}

#delete-p #head {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
}

#delete-p #head img {
    width: 60px;
    margin-right: 15px;
    border-radius: 5px;
}

#delete-p #description {
    margin-top: 40px;
    margin-bottom: 40px;
}

#delete-p button {
    background-color: #ED4245;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 12px;
    font-size: 18px;
    border-radius: 5px;
    margin-right: 10px;
    transition: 100ms ease;
    border-style: none;
    margin-bottom: 10px;
    margin-right: 0px;
}

#delete-p #loading-btn svg {
    width: 12px;
    height: 12px;
    padding-bottom: 5px;
}

#delete-p #loading-btn {
    margin-left: 10px;
    padding-left: 80px;
}

#delete-p #error-delete {
    color: gray;
    font-size: 15px;
    margin-bottom: 10px;
}

#delete-p button:hover {
    cursor: pointer;
    background-color: #ED4245c7;
}

#delete-p button[disabled] {
    background-color: #a73133;
}

#delete-p button[disabled]:hover {
    cursor: default;
    background-color: #a73133;
}

@media only screen and (max-width: 555px) {
    #delete-p {
        margin-left: -135px;
        width: 480%;
        margin-top: -50px;
        margin-bottom: -120px;
    }
}
#terms-window {
    margin: 0px auto;
    margin-top: 100px;
    background-color: var(--card-background);
    border-radius: 5px;
    width: 75%;
    padding: 0px 60px;
    text-align: left;
    padding-bottom: 30px;
}

#terms-window #content * {
    text-align: justify;
}

#terms-window #head #title {
    font-size: 30px;
}

#terms-window #head #last-modified {
    color: grey;
    font-size: 12.5px;
}

#terms-window #content {
    padding: 0;
}

#terms-window #content section {
    font-size: 13px;
    color: grey;
}

#terms-window #content section p {
    font-size: 28px;
    color: white;
    margin-bottom: 0;
}

#terms-window #content section a {
    color: #5865F2;
    text-decoration: underline;
    transition: 100ms ease;
}

#terms-window #content section a:hover {
    color: #7783ff;
    cursor: pointer;
}

#terms-window #content section ul li {
    text-align: start;
}

@media only screen and (max-width: 720px) {
    #terms-window {
        margin: unset 10px;
        padding: 20px;
        margin-top: 50px;
    }
}
#info-window {
    margin: 0px auto;
    margin-top: 100px;
    background-color: var(--card-background);
    border-radius: 5px;
    width: 75%;
    padding: 0px 60px;
    text-align: left;
    padding-bottom: 30px;
}

#info-window #head #title {
    font-size: 30px;
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-justify-content: center;
            justify-content: center;
}

#info-window #head #title img {
    width: 30px;
    margin-left: 10px;
}

#info-window #head #sub-title {
    color: grey;
    font-size: 12.5px;
}

#info-window #content {
    padding: 0px;
}

#info-window #content section {
    font-size: 13px;
    color: grey;
}

#info-window #content section p {
    font-size: 28px;
    color: white;
    margin-bottom: 0;
}

#info-window #content section ul li a, #info-window #content #about-partner a {
    color: #5865F2;
    text-decoration: underline;
    transition: 100ms ease;
}

#info-window #content section ul li a:hover, #info-window #content #about-partner a {
    color: #7783ff;
    cursor: pointer;
}

#info-window #content #list {
    width: 400px;
}


#info-window #content #about-partner {
    color: grey;
}

#info-window #content #list #partner-card {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    width: 400px;
    background-color: #303336;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

#info-window #content #list #partner-card #info .about {
    color: gray;
    font-size: 13px;
    text-align: justify;
}

#info-window #content #list #partner-card img {
    height: 100px;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.4);
    margin-right: 15px;
}

button#req{
    background-color: #5865f2;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 12px;
    font-size: 18px;
    border-radius: 5px;
    transition: .1s ease;
    border-style: none;
    margin-bottom: 10px;
    margin-right: 0;
    color: #fff;
    margin-top: 20px;
    cursor: pointer;
}

@media only screen and (max-width: 720px) {
    #info-window {
        margin: unset 10px;
        padding: 20px;
        margin-top: 50px;
    }

    #info-window #content #list #partner-card {
        -webkit-transform: scale(0.7);
                transform: scale(0.7);
        margin-left: -70px;
    }
}
