#popup {
    margin-top: 150px;
    background-color: #23272a;
    width: 50%;
    padding: 20px 30px;
    text-align: left;
}

#SELECT_BOT {
    color: #fff;
    background-color: gray;
    outline: none;
    padding: 5px 10px;
    font-size: 15px;
}

#popup button {
    background-color: #57F287;
    border-style: solid;
    border-color: transparent;
    margin-top: 10px;
    color: #000;
    padding: 5px 19px;
    transition: 200ms ease;
}

#popup button:hover {
    background-color: #57f288bf;
    cursor: pointer;
}