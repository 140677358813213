#mobile-menu{
  display: none;
}

html{
  scroll-behavior: smooth;
}

body{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  margin: 0;
  overflow-x: hidden;
  background-color: #ffffff;
}

header{
  height: 700px;
}

#login-prompt{
  margin-top: -10px;
}

#brand-name{
  color: var(--font-white);
  font-size: 25px; 
  margin-top: -67px;
  margin-left: 80px;
  font-weight: 400;
}

#brand-logo{
  height: 50px;
  padding: 24px;
  border-radius: 100%;
}

#header-menu{
  height: 100px;
  background-color: #23272A;
  position: sticky;
  top: 0;
  z-index: 1000;
  width: 100%;
}



#header-menu ul{
  padding-right: 30px;
  padding-top: 0px;
  z-index: 12;
  position: relative;
  margin-top: 0px;
}

#hover-menu ul{
  padding: 20px !important;
}

#header-menu ul svg, #header-menu ul img{
  height: 28px;
  vertical-align: text-bottom;
  width: 20px;
  margin-top: 10px;
  padding-right: 5px;
}


#header-menu ul li{
  display: inline;
  float: right;
  list-style: none;
  padding: 0px;
  margin-top: -50px;
}



#header-menu ul li a{
  text-decoration: none;
  color: var(--font-white);
  font-size: 23px;
  transition: 0.2s;
  fill: white;
  width: 100%;
  display: block;
  padding: 10px 0px 15px 20px;
}

#hover-menu-ul a{
  background-color: white;
  font-size: 20px !important;
  padding-bottom: 10px;
}


#hover-menu-ul a:hover{
  background-color: var(--hover-grey) !important;
  color: white !important;
  fill: white !important;
}

#header-menu ul li a:hover{
  color: var(--hover-grey);
  fill: var(--hover-grey) !important;
}

#hover-menu-ul a:first-child{
  border-radius: 10px 10px 0px 0px;
}

#hover-menu-ul a:last-child{
  border-radius: 0px 0px 10px 10px;
}

#login{
  display: block;
  height: fit-content;
  height: 30px;
}

#header-hover-thing{
  height: 50px;
}

#header-hover-thing:hover ~ #hover-menu{
  margin-right: -70px;
  display: block;
}

#hover-menu-ul #logout{
  color: red !important;
}

#hover-menu:hover{
  display: block;
}

#hover-menu{
  /* background-color: white; */
  display: none;
  position: absolute;
  margin-right: 60px !important;
  border-radius: 10px;
  margin-left: -30px;
  padding: 0px;
  /* padding-bottom: 20px; */
}

#hover-menu-ul a{
  color: black !important;
}

.header-login-img{
  height: auto !important;
  width: 30px !important;
  border-radius: 10000px;
  padding: 0px !important;
}  

@media only screen and (max-width: 905px){
  #mobile-menu{
    display: block;
    height: 0px;
    position: fixed;
    width: 100%;
    margin-bottom: 100px;
    z-index: 1001;
    margin-top: -60px;
  }

  #hamburger{
    float: right;
    margin-top: -50px;
    margin-right: 30px;
  }

  #branding-etc{
    background-color: #23272A;
  }

  #mobile-menu svg{
    height: 100px !important;
  }

  #menuul img{
    vertical-align: middle;
    width: 40px !important;
    margin-top: -5px;
  }

  #header-menu{
    display: none;
  }

  #brand-logo-mobile{
    height: 50px;
    border-radius: 1000px;
    margin: 20px;
    
  }

  #brand-name-mobile{
    margin-top: -65px;
  margin-left: 80px;
  padding-bottom: 23px;
  font-size: 20px;
  
  }

  #menuul{
    width: 50%;
    height: 100vh;
    background-color: #34393d;
    float: right;
    margin-top: -198px;
    z-index: 1031;
    position: relative;
    font-size: 25px;
    padding: 30px;
    margin-right: -100%;
    transition: all 0.5s ease;
  }

  #menuul svg{
    height: 30px !important;
    fill: white;
    margin-right: 10px;
    vertical-align: bottom;
  }

  #menuul a{
    text-decoration: none;
  }

  #menuul li{
    margin-top: 30px;
    list-style: none;

  }

  ul li#user{ 
    padding: 20px;
    background-color: #1f2c36;
    border-radius: 10px;
    padding-bottom: 25px;
  }

  ul li#user a div div a svg {
    position: relative;
    top: -5px;
  }
  #hover-menu{
    background-color: unset;
    width: 100%;
    font-size: 20px;
    margin-left: 0px;
  }

  #hover-menu svg{
    display: none;
  }

  #hover-menu-ul{
    margin-top: 10px;
  }

  #hover-menu-ul a{
    color: white !important;
    margin-top: 0px;
    display: block;
    background-color: unset;
  }

  #login-prompt{
    margin-top: 0px;
  }

  #hover-menu{
    margin-left: 10px;
  }

  #hover-menu-ul a:last-child{
    margin-bottom: -10px;
  }

  #branding-etc {
    padding-bottom: 10px;
  }
  
  #header-wave-svg path {
    fill: transparent;
  }

  #hover-menu-ul {
    padding-left: 0px;
    margin-left: -30px;
    background-color: gray;
    width: fit-content;
    padding-left: 10px;
    padding-right: 30px;
    padding-top: 20px;
    padding-bottom: 10px;
    border-radius: 5px;
  }
} 