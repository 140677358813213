#headline-all{
    width: 80%;
    margin: auto;
    color: white;
    margin-top: 100px;
    height: 100px;
    background-color: var(--card-background);
    font-size: 30px;
    padding-top: 30px;
    text-align: center;
    margin-bottom: 50px;
}