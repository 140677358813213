#sub-headline{
    font-size: 15px;
    color: grey;
}

#box-container{
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    grid-template-rows: repeat(1, 1fr);
    justify-content: left;
    grid-column-gap: 30px;
    padding-top: 10px;
    height: 420px;
    width: calc(80% - 50px);
    margin: auto;
    height: fit-content;
    padding-bottom: 100px;
}

#page-thing{
    font-family: 'Work Sans', sans-serif;
    background-color: #23272A;
    width: fit-content;
    margin:auto;
    margin-top: 50px;
    margin-bottom: 10px;
    height: fit-content;
    display: flow-root;
    border-radius: 5px;
}

#page-thing svg{
    height: 20px;
}

#left-page-thing{
    color: white;
    font-size: 30px;
    float: left;
    padding: 22px 10px;
    margin: 10px;
    background-color: #5865F2;
    border-radius: 5px;
    width:max-content;
    cursor: pointer;
}

#before-page-thing, #current-page-thing, #after-page-thing{
    float: left;
    font-size: 30px;
    color: white;
    display: block;
    margin-top: 20px;
}

#current-page-thing{
    float: left;
    font-size: 30px;
    padding: 8px;
    padding-top: 10px;
    margin: 10px;
    background-color: #5865F2;
    border-radius: 5px;
    display: block;
    width: max-content;
}

#right-page-thing {
    float: left;
    font-size: 30px;
    padding: 22px 10px;
    margin: 10px;
    background-color: #5865F2;
    border-radius: 5px;
    display: block;
    width: max-content;
    cursor: pointer;
}

#before-page-thing a, #after-page-thing a{
    color: white;
    padding: 10px;
    text-decoration: none;
}
