#manage-container{
    margin-top: 50px;
    color: white;
    width: 80%;
    background-color: #2b2e31;
    margin: auto;
    margin-top: 150px;
}

#list{
    width: 300px;
    height: max-content;
    float: left;
    margin-top: -15px;
    margin-bottom: -15px;
}

#list a{
    text-decoration: none;
}

#list ul li{
   padding: 50px;
   margin-left: -40px;
    list-style: none;
    margin-right: 30px;
    transition: all 0.3s;
    border-right: 1px solid rgb(73, 72, 72);
}
#list ul li:hover{
    background-color: grey;
}

#content-manage{
    padding-top: 30px;
    border-left: 1px solid rgb(73, 72, 72);
    margin-left: 269px;
}

.tooltip {
    background-color: grey;
    padding: 3px;
    padding-left: 7px;
    padding-right: 7px;
	position: relative;
    font-style: normal;
    margin-left: 10px; 
    font-size: 12px;
    border-radius: 1000px;
    vertical-align: text-top;
}

.tooltip span[role=tooltip] {
	display: none;
}

.tooltip:hover span[role=tooltip] {
	display: block;
	position: absolute;
	bottom: 2em;
	left: -6em;
	width: 15em;
	padding: 0.7em;
	z-index: 100;
    color: white;
	background-color: #1B1C1F;
    border: 1px solid black;
	border-radius: 0.2em;
}

#button-prof{
    background-color: #1B1C1F;
    border: 1px solid black;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    margin-top: 10px;
}

#button-prof-del, #button-prof {
    width: 150px;
    /* margin-left: 10px; */
    margin-right: 15px;
}

#btns {
    display: inline-flex;
}

#button-prof-del{
    background-color: #ED4245;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    margin-top: 10px;
}

#gift-cr * {
    width: 90%;
}

#button-prof-gift{
    background-color: #57F287;
    padding: 10px;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;
    margin-top: 10px;
    width: 20%;
    margin: 10px 30px 20px;
    color: #000;
}

#button-prof:hover, #button-prof-del:hover, #button-prof-gift:hover{
    transform: scale(110%);
}

#error{
    width: 30%;
    margin: auto;
    text-align: center;
    padding: 10px;
    display: block;
    margin-top: 20px;
    background-color: rgb(255, 0, 0,0.3);
    border: 2px solid rgb(255, 0, 0,0.5);
    border-radius: 10px;
    /* margin-left: 300px; */
    display: inline-block;
    bottom: 0;
    display: none;
}

#test-container{
    border: 1px solid black;
    margin-top: 10px;
    padding: 30px;
    margin-right: 30px;
    border-radius: 5px;
    margin-bottom: 20px;
    display: flow-root;
    margin-left: 30px;
}

#test-container img{
    height: 100px;
    float: left;
}

#element{
    margin-right: 50px;
    margin-bottom: 20px;
    margin-top: -30px;
}

#test-container #desc{
    margin-left: 110px;
}

#test-container #buttons button{
    margin-right: 20px;
    padding: 10px;
    background-color: var(--card-background);
    color: white;
    border: 2px solid var(--buttons);
    cursor: pointer;
    transition: all 0.3s;
}

#test-container #buttons button:disabled{
    border: 2px solid grey !important;
}

#test-container #buttons button:disabled:hover{
    background-color: grey;
}

#others-container{
    margin-top: 100px;
    display: flex;
}

#test-container #buttons button:hover{
    background-color: var(--buttons);
}
 
.deny:hover{
    background-color: red !important;
    border: 2px solid red !important;
}

.approve:hover{
    background-color: green !important;
    border: 2px solid green !important;
}

#report-popup input[type="checkbox"]{
    height: 15px;
    width: 15px;
}

#rep-container{
    height: fit-content;
    max-width: 1000px;
    margin-left: 30px;
    margin-top: 30px;
    margin-right: 30px;
    display: block;
    background-color: #31363a;
    border-radius: 10px;
}

#rep-container img{
    padding: 20px;
    height: 100px;
}

#rep-container #name{
    margin-top: -125px;
    margin-left: 130px;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 75px;
}

#state{
    float: right;
    margin-top: -90px;
    margin-right: 50px;
    font-size: 15px;
    font-weight: bold;
    width: 300px;
}

#state-state{
    font-weight: 100;
}

#current{
    display: none;
}

#list #menu-mobile{
    height: unset;
}

@media only screen and (max-width: 905px){
    #content-manage {
        padding-top: 30px;
        border-left: 0px solid rgb(73, 72, 72);
        margin-left: 10px;
    }

    #list{
        width: 100%;
        height: max-content;
        float: unset;
    }

    #list ul li {
        padding: 10px;
        margin-left: 0px;
        list-style: none;
        margin-right: 0px;
        transition: all 0.3s;
        border-right: 0px solid rgb(73, 72, 72);
    }

    #current{
        height: 40px;
        padding-left: 15px;
        border-bottom: 1px solid rgb(70, 70, 70);
        display: block;
    }

    #current svg{
        float: right;
        margin-top: -25px;
        fill: white;
        margin-right: 15px;
        height: 20px;
    }

    .title{
        margin-top: 30px;
        font-size: 20px;
    }

    #list #menu-mobile{
        height: 0px;
        overflow: hidden;
        transition: all 0.3s;
        margin-left: -30px;
    }
    #content-manage{
        padding: 20px;
        margin-left: 0px;
    }

    #rep-container{
        display: flow-root;
    }

    #state{
        margin-top: -30px;
        float: left;
        margin-left: 20px;
        width: 100%;
    }

    #state-title{
        margin-top: 10px;
    }
    
    #state-state{
        padding-bottom: 10px;
        margin-right: 10px;
    }

    #rep-container #name{
        font-size: 20px;
        margin-top: -20px;
        margin-left: 20px;
        font-size: 20px;
        font-weight: bold;
        padding-bottom: 0px;
    }
    .input{
        width: 90% !important;
    }

    #btns{
        display: unset;
    }

    #button-prof, #button-prof-del{
        float: left;
        margin-bottom: 20px;
    }

    .guilds a{
        padding: 20px;
    }

    #manage-container #content-manage .card-container .card {
        transform: scale(0.85);
        margin-left: -27.5px;
        margin-bottom: -60px;
    }

    #content-manage {
        margin-bottom: 40px;
    }
}

.redeembtn {
    color: #fff;
    text-decoration: none;
    background-color: #5865F2;
    padding: 3px 13px;
    border-radius: 4px;
    transition: 150ms ease;
    position: relative;
    bottom: 5px;
}

.redeembtn:hover {
    background-color: #4853c9;
}