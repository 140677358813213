
#all-container{
  display: grid;
  grid-template-columns: 50% 50%;
  transition: all 1s ease;
}

.ace_line, .ace_text, .ace_meta, .ace_entity, .ace_xml, .ace_indent-guide{
  font: 12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace !important;
}

#form-container{
  background-color: #272727;
  margin: auto;
  margin-top: 200px;
  width: 90%;
  color: white;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 100px;
  height: fit-content;
}

#form-container-title{
  font-size: 30px;
  text-align: center;
  padding-top: 10px;
  margin: auto;
  width: calc(80% + 20px);
  border-bottom: 2px solid grey;
  margin-bottom: 40px;
}

#form-container form{
  display: grid;
  margin: auto;
  width: 80%;
  padding-bottom: 30px;
}

#longdescription{
  width: 100% !important;
  min-height: 300px;
}

#longdescription textarea{
  min-width: unset !important;
  padding: 0px !important;
}

#form-container form label{
  font-size: 20px;
  font-weight: 300;
}

#form-container form input::placeholder{
  color: rgb(219, 219, 219);
}

#form-container form input, #form-container form textarea{
  height: 30px;
  display: block;
  margin-bottom: 10px;
  margin-top: 2px;
  background-color: var(--font-grey);
  border: 1px solid grey;
  padding: 23px 10px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  border-radius: 10px;
}

#form-container form input:focus{
  border-color: #0192cf;
  transition: all 0.3s;
  transform: scale(1.01);
}

input#news{
  display: none !important;
}

#right-cont{
  border: 3px solid var(--font-grey);
  height: fit-content;
  width: 90%;
  margin-top: 200px;
  transition: all 1s ease;
  overflow: hidden;
  padding-bottom: 20px;
}

#form-container form button{
  height: 40px;
  display: block;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color:#2c2c2c;
  border: 0px solid var(--buttons);
  padding: 6px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  font-weight: 600;
  padding-left: 10px;
  border-radius: 10px;
  transition: 0.3s ease;
  cursor: pointer;
}

#form-container form button:hover{
  margin-top: 5px;
  -webkit-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.75);
  background-color: #00b4ff;
  margin-bottom: 15px;
}

#form-container form input:focus{
  outline: none;
}

#form-container #box{
  background-color: var(--font-grey);
  width: clamp(400px, 90%, 100%) !important;
  margin-top: 50px;
  padding: 20px;
  border-radius: 10px;
  color: var(--font-white);
  margin-right: 30px;
  transition: 0.3s ease;
  -webkit-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.75);
  box-shadow: 10px 10px 23px 0px rgba(0,0,0,0.75);
}
  
#box img{
  height: 100px;
  margin-top: -40px;
  margin-left: 10px;
  -webkit-box-shadow: 0px 0px 38px -10px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 0px 38px -10px rgba(0,0,0,0.75);
  box-shadow: 0px 0px 38px -10px rgba(0,0,0,0.75);
  border-radius: 20px;
  object-fit: cover;
}
  
.bot-name{
  font-weight: bolder;
  font-size: 30px;
}
  
.disc{
  color: grey;
  display: inline;
  font-size: 20px;
}
  
.description{
  color: rgb(192, 192, 192);
  margin: auto;
  margin-top: 20px;
  width: 90%;
  font-weight: bolder;
}
  
#button{
  height: 30px;
  width: 90%;
  text-align: center;
  padding: 5px;
  margin: auto;
  padding-top: 10px;
  margin-top: 10px;
  background-color: #343a40;
  font-weight: bolder;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}
  
#button:hover{
  padding-top: 10px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  margin-top: 10px;
  width: 90%;
  background-color: #464d53;
  height: 35px !important;
}

#error{
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 10px;
  display: block;
  margin-top: 20px;
  background-color: rgb(255, 0, 0,0.3);
  border: 2px solid rgb(255, 0, 0,0.5);
  border-radius: 10px;
}

button:focus{
  outline: none;
}

.tag-box{
  display: inline-flex;
  margin-left: 10px;
  background-color: #343a40;
  border-radius: 10px;
  padding: 10px;
}

#tags-title{
  background-color: #343a40;
  width: fit-content;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  margin-left: 40px;
}

textarea{
  min-height: 100px;
  display: block;
  margin-bottom: 10px;
  margin-top: 2px;
  background-color: var(--font-grey);
  border: 1px solid grey;
  padding: 6px;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  padding-left: 10px;
  border-radius: 10px;
  overflow-y: hidden;
  outline: none;
  width: 95%;
}

#submit:hover{
  bottom: 0px !important;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 50;
  stroke-miterlimit: 10;
  stroke: #7ac142;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark {
  width: 70px;
  height: 70px;
  border-radius: 80%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  margin-top: -40px;
  margin-bottom: 10px;
  box-shadow: inset 0px 0px 0px #7ac142;
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 2);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 30px #7ac142;
  }
}

#color {
  width: 300px;
}

#right-cont {
  background-color: rgb(59, 59, 59);
}

#form-container-inputs form #banner {
  width: 97%;
}

@media only screen and (max-width: 555px) {
  #all-container {
    display: block;
  }

  #right-cont {
    width: 100%;
    background-color: transparent;
    border-color: transparent;
  }

  #form-container-inputs form input, #form-container-inputs form #banner {
    width: 100%;
  }

  #form-container {
    margin-top: 70px;
  }

  #right-cont {
    margin-top: -80px;
  }
}