#user_card {
    background-color: #23272a;
    width: 40%;
    padding: 15px 20px;
    border-radius: 7px;
    margin: 0px auto;
    position: relative;
}

#avatar {
    width: 80px;
    border-radius: 100%;
    border: solid 2px;
}

#username {
    margin: 5px 0px 0px 15px;
    font-size: 20px;
    width: 100%;
}

#bio {
    color: gray;
    font-size: 15px;
    margin-top: -2px;
    width: calc(100% - 120px);
}

#pf-links {
    position: absolute;
    right: 30px;
    top: 10px;
    width: 100px;
}

.pf-link {
    color: gray;
    display: inline-flex;
    align-items: center;
    background-color: #1b1d1f;
    cursor: pointer;
    transition: 50ms ease;
    padding: 0px 10px;
    width: 105px;
    transform: scale(0.9);
}

.pf-link:hover {
    background-color: #1f2224;
}

.pf-link svg {
    fill: gray;
    width: 20px;
    margin-right: 15px;
}

#info_general {
    display: inline-flex;
    align-items: center;
    width: 100%;
}

@media only screen and (max-width: 1000px) {
    #user_card {
        width: 80%;
    }

    #info_general {
        display: block;
    }

    #pf-links {
        position: unset;
        width: 100%;
        margin-top: 10px;
    }

    .pf-link {
        font-size: 13px;
        margin-right: 5px;
        margin-bottom: 0px;
        width: 90px;
        transform: scale(1);
    }

    .pf-link svg {
        width: 17px;
    }

    #username {
        margin-left: 0px;
    }
}