#terms-window {
    margin: 0px auto;
    margin-top: 100px;
    background-color: var(--card-background);
    border-radius: 5px;
    width: 75%;
    padding: 0px 60px;
    text-align: left;
    padding-bottom: 30px;
}

#terms-window #content * {
    text-align: justify;
}

#terms-window #head #title {
    font-size: 30px;
}

#terms-window #head #last-modified {
    color: grey;
    font-size: 12.5px;
}

#terms-window #content {
    padding: 0;
}

#terms-window #content section {
    font-size: 13px;
    color: grey;
}

#terms-window #content section p {
    font-size: 28px;
    color: white;
    margin-bottom: 0;
}

#terms-window #content section a {
    color: #5865F2;
    text-decoration: underline;
    transition: 100ms ease;
}

#terms-window #content section a:hover {
    color: #7783ff;
    cursor: pointer;
}

#terms-window #content section ul li {
    text-align: start;
}

@media only screen and (max-width: 720px) {
    #terms-window {
        margin: unset 10px;
        padding: 20px;
        margin-top: 50px;
    }
}