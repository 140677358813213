* {
    user-select: none;
}

#CONTENT {
    padding: 150px;
}

#BOX, #BOX-VOTED {
    border: solid transparent 2px;
    width: 1000px;
    border-radius: 10px;
    background-color: rgb(39, 39, 39);
    color: #fff;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5);
    margin: 0px auto;
}

#ICON {
    width: 200px;
    margin: 20px;
    border-radius: 10px;
}

#NAME {
    font-size: 25px;
    margin-top: 10px;
    width: fit-content;
}

#VOTE_BTN {
    background-color: rgb(68, 179, 127);
    color: rgb(39, 39, 39);
    /* color: #fff; */
    padding: 8px 50px;
    border: solid transparent;
    border-radius: 5px;
    font-size: 17px;
    margin: 20px;
    margin-top: 40px;
    transition: background-color 200ms ease;
}

#VOTE_BTN:hover {
    background-color: #57f28883;
    cursor: pointer;
}

#BACK_TO {
    margin-left: 20px;
    width: fit-content;
}

#BOX p {
    color: gray;
    transition: color 100ms ease;
}

#BACK_TO:hover {
    cursor: pointer;
    color: lightgray;
}

#BOX-VOTED {
    margin: 0px auto;
    color: gray;
}

#VOTED_NONE {
    width: fit-content;
}

#VOTED_NONE:hover {
    color: gray;
    cursor: default;
}

@media only screen and (max-width: 1050px) {
    #BOX, #BOX-VOTED {
        margin: auto;
        width: 95%;
        margin-top: -50px;
        margin-bottom: -120px;
    }

    #CONTENT {
        padding: 150px 0px;
    }
}

#vote-s-links a {
    color: #5865F2;
    text-decoration: none;
    transition: 100ms ease;
}

#vote-s-links a:hover {
    color: #737efa;
}