#delete-p_center {
    padding: 150px;
}

#delete-p {
    border: solid transparent 2px;
    width: 1000px;
    border-radius: 10px;
    background-color: rgb(39, 39, 39);
    color: #fff;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5);
    margin: 40px auto;
    padding: 10px 20px;
    margin-top: 140px;
}

#delete-p #head {
    display: inline-flex;
    align-items: center;
}

#delete-p #head img {
    width: 60px;
    margin-right: 15px;
    border-radius: 5px;
}

#delete-p #description {
    margin-top: 40px;
    margin-bottom: 40px;
}

#delete-p button {
    background-color: #ED4245;
    width: fit-content;
    padding: 5px 12px;
    font-size: 18px;
    border-radius: 5px;
    margin-right: 10px;
    transition: 100ms ease;
    border-style: none;
    margin-bottom: 10px;
    margin-right: 0px;
}

#delete-p #loading-btn svg {
    width: 12px;
    height: 12px;
    padding-bottom: 5px;
}

#delete-p #loading-btn {
    margin-left: 10px;
    padding-left: 80px;
}

#delete-p #error-delete {
    color: gray;
    font-size: 15px;
    margin-bottom: 10px;
}

#delete-p button:hover {
    cursor: pointer;
    background-color: #ED4245c7;
}

#delete-p button[disabled] {
    background-color: #a73133;
}

#delete-p button[disabled]:hover {
    cursor: default;
    background-color: #a73133;
}

@media only screen and (max-width: 555px) {
    #delete-p {
        margin-left: -135px;
        width: 480%;
        margin-top: -50px;
        margin-bottom: -120px;
    }
}