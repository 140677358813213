.box {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  background-color: #1b1f21;
  border-radius: 10px;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
}

.box iframe {
  margin: 50px 5%;
  border: 0px solid;
}

#infos-general {
  max-width: calc(1200px - 6%);
  padding: 20px 3%;
  padding-top: 50px;
  z-index: 2;
  position: relative;
  padding-bottom: 0px;
}

#infos-general #head {
  display: flex;
}

#infos-general #head #pfp {
  width: 180px;
  height: 180px;
  position: relative;
  left: -90px;
  top: -90px;
  margin-right: -50px;
  margin-bottom: -60px;
  border-radius: 10px;
  border-width: 2px;
  border-style: solid;
  background-color: #1b1f21;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.75);
}

#infos-general #head {
  position: relative;
  z-index: 4;
}

#infos-general #head div #name {
  position: relative;
  font-size: 25px;
  top: -22px;
}

#infos-general #head div #shortdesc {
  position: relative;
  font-size: 15px;
  top: -22px;
  color: gray;
}

#infos-general #head div #name .bot-badge {
  position: relative;
  top: 8px;
  left: 3px;
  width: 28px;
}

#infos-general #head div #tags {
  position: relative;
  top: -10px;
  width: 900px;
  display: block;
  word-wrap: break-word;
  height: fit-content;
}

#infos-general #head div #tags .tag {
  color: rgb(208, 208, 208);
  background-color: rgb(99, 99, 99);
  margin-right: 5px;
  padding: 2px 6px;
  border-radius: 3px;
  white-space: nowrap;
  transition: 100ms ease;
  position: relative;
  display: inline-block;
  margin-bottom: 5px;
}

#infos-general #buttons {
  display: block;
  word-wrap: break-word;
  width: 900px;
}

#infos-general #buttons .button {
  white-space: nowrap;
  display: inline-block;
  margin-bottom: 5px;
}

#infos-general #head div #tags .tag:hover {
  color: rgba(208, 208, 208, 0.75);
  background-color: rgba(99, 99, 99, 0.75);
  cursor: pointer;
}

#infos-general #devs {
  word-wrap: break-word;
}

#infos-general #devs .user {
  display: flex;
  align-items: center;
  color: rgb(208, 208, 208);
  width: fit-content;
  padding: 0px 5px;
  border-radius: 5px;
  transition: 100ms ease;
  margin-right: 5px;
  font-size: 14px;
  float: left;
  background-color: rgb(99, 99, 99);
  white-space: nowrap;
  /* display: inline-block; */
  margin-bottom: 5px;
}

#infos-general #devs .user:hover {
  color: rgba(208, 208, 208, 0.75);
  background-color: rgba(99, 99, 99, 0.75);
  cursor: pointer;
}

#infos-general #devs .user #badges {
  padding: 7px;
  padding-right: 0px;
  display: flex;
  align-items: center;
}

#infos-general #devs .user #badges svg {
  position: relative;
  width: 20px;
  height: 20px;
  transform: scale(0.9);
}

#infos-general #buttons {
  clear: both;
  padding: 20px 0px;
  display: flex;
  align-items: center;
}

#infos-general #buttons .button {
  background-color: #5865F2;
  width: fit-content;
  padding: 5px 12px;
  font-size: 18px;
  border-radius: 5px;
  margin-right: 10px;
  transition: 100ms ease;
}

#infos-general #buttons .button:hover {
  cursor: pointer;
  background-color: #5865f2c7;
}

#infos-general #buttons #report {
  background-color: #ED4245;
}

#infos-general #buttons #report:hover {
  background-color: #ED4245c7;
}

#infos-general #buttons #edit {
  color: #000;
  background-color: #FEE75C;
}

#infos-general #buttons #edit:hover {
  background-color: #FEE75Cc7;
}

#infos-general #buttons .additional {
  background-color: rgba(128, 128, 128, 0.685);
  color: #fff;
}

#infos-general #buttons .additional:hover {
  background-color: #8080808a;
}

#bannerImg {
  width: 100%;
  margin-left: -57px;
  margin-top: -50px;
  height: 150px;
  background-size: cover;
  background-position: center;
  position: absolute;
  z-index: 0;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  left: 57px;
  image-rendering: optimizeQuality;
}

#bannerGradient {
  width: 100%;
  margin-left: -57px;
  left: 57px;
  margin-top: -50px;
  height: 150px;
  position: absolute;
  z-index: 1;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-image: linear-gradient(transparent, #1b1f21);
}

#infos-general #stats {
  color: gray;
  font-size: 15px;
  text-align: right;
  position: relative;
  top: -5px;
  right: -40px;
}

.preview {
  width: 90%;
}

#content-box{
  height: fit-content;
  max-width: 1197px;
  margin: auto;
  margin-bottom: 100px;
  padding: 10px;
}

.outdated{
  background-color: rgba(255, 0, 0, 0.418);
  border-left: 3px solid red;
}
.degraded{
  background-color: rgb(160, 160, 40);
  border-left: 3px solid yellow;
}

.news{
  background-color: #1b1f21;
  border-left: 3px solid #50595e;
  font-size: 20px;
  box-shadow: 0px 0px 30px 0px rgb(0 0 0 / 75%);
}

#unapproved {
  width: 80%;
  height: fit-content;
  margin-left: 10%;
  margin-right: 10%;
  background-color: #BC3131;
  border: 3px red solid;
  border-radius: 10px;
  color: white;
  text-align: center;
  margin-bottom: 100px;
}

#cert-req-parent {
  width: 100vw;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 25px;
  background-color: #5865F2;
  z-index: 1000;
  color: #fff;
  text-align: center;
  padding: 8px 0px;
}

#cert-req {
  border: solid 1px #fff;
  color: #fff;
  transition: 200ms ease;
}

#cert-req:hover {
  border-color: #ff9797;
  color: #ff9797;
}

@media only screen and (max-width: 555px) {
  .box {
    width: 85%;
  }

  #infos-general {
    margin-top: -50px;
    margin-bottom: -50px;
  }

  #infos-general #head #pfp {
    left: 0px;
    top: -40px;
    width: 100px;
    height: 100px;
  }

  #infos-general #head {
    display: block;
  }

  #infos-general #head div #name {
    display: block;
    margin-top: 50px;
  }

  #infos-general #head div #shortdesc {
    display: block;
    margin-top: -25px;
    margin-bottom: -25px;
  }

  #infos-general #head div #tags, #infos-general #buttons {
    display: block;
    margin-bottom: 10px;
    width: 300px;
  }

  #infos-general #buttons .button {
    width: 100%;
    margin-left: -15px;
    transform: scale(0.9);
  }

  #infos-general #stats {
    left: -5px;
  }

  #longdesc-frame {
    width: 90%;
  }

  #cert-req-parent {
    height: 60px;
  }
}