#container-apply{
    width: fit-content;
    margin: auto;
    background-color: var(--card-background);
    padding: 50px;
    color: white;
    margin-top: 100px;
}

#sub{
    margin-bottom: 20px;
}

.input-desc{
    margin-top: 10px;
    margin-bottom: 5px;
    margin-left: 3px;
}

.input{
    width: 97%;
    border-radius: 5px;
    background-color: #1B1C1F;
    border: 1px solid black;
    height: 20px;
    outline: none;
    padding: 10px;
    color: white;
    margin-bottom: 10px;
}

.input ::placeholder{
    color: grey;
}

.input[type="checkbox"]{
    width: 30px;
}
