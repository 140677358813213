#cert_center {
    padding: 150px;
}

#cert {
    border: solid transparent 2px;
    width: 1000px;
    border-radius: 10px;
    background-color: rgb(39, 39, 39);
    color: #fff;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.5);
    margin: 0px auto;
    padding: 10px 20px;
}

#cert #head {
    display: inline-flex;
    align-items: center;
}

#cert #head img {
    width: 60px;
    margin-right: 15px;
    border-radius: 5px;
}

#cert #description {
    margin-top: 40px;
    margin-bottom: 40px;
}

#cert button {
    background-color: #5865F2;
    width: fit-content;
    padding: 5px 12px;
    font-size: 18px;
    border-radius: 5px;
    margin-right: 10px;
    transition: 100ms ease;
    border-style: none;
    margin-bottom: 10px;
    margin-right: 0px;
    color: #fff;
}

#cert #loading-btn svg {
    width: 12px;
    height: 12px;
    padding-bottom: 5px;
    filter: brightness(0) invert(1);
}

#cert #loading-btn {
    margin-left: 10px;
    padding-left: 80px;
}

#cert-request {
    display: inline-flex;
    align-items: center;
}

#cert-request img {
    width: 25px;
    filter: brightness(0) invert(1);
    margin-left: 8px;
}

#cert #error-cert {
    color: gray;
    font-size: 15px;
    margin-bottom: 10px;
}

#cert button:hover {
    cursor: pointer;
    background-color: #5865F2c7;
}

@media only screen and (max-width: 555px) {
    #cert {
        margin-left: -135px;
        width: 480%;
        margin-top: -50px;
        margin-bottom: -120px;
    }
}