#p404 #CONTENT h1 {
    color: #fff;
    text-align: center;
    margin-top: 100px;
}

#p404 #CONTENT h3 {
    color: gray;
    text-align: center;
}

#p404 #BOX {
    display: flow-root;
    height: 200px;
}

#p404 #BOX strong {
    float: left;
    margin-left: 30px;
    font-size: 50px;
    font-weight: bold;
    margin-top: 40px;
}

#p404 #BOX section {
    float: left;
    margin-left: -95px;
    font-size: 20px;
    color: gray;
    margin-top: 100px;
}

#p404 #BOX p {
    text-align: left;
    margin-left: 20px;
    margin-top: 160px;
    width: fit-content;
    float: left;
    position: relative;
    left: -355px;
}

@media only screen and (max-width: 555px) {
    #p404 #CONTENT {
        width: 15%;
        margin-bottom: -90px;
    }

    #p404 #BOX strong {
        margin-top: 35px;
        margin-left: 10px;
    }

    #p404 #BOX section {
        margin-left: -95px;
        font-size: 15px;
    }

    #p404 #BOX p {
        left: -10px;
        top: -125px;
    }

    #p404 #BOX {
        margin-left: -115px;
        margin-bottom: -40px;
    }
}